import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getfunctions } from '../utils/utils';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        if (i !== 'func') this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    const functions = getfunctions();
    if (functions) {
      const f = functions.split(',');
      this.setState({ func: f });
    } else {
      this.setState({ func: [] });
    }
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          document.body.classList.toggle('sidebar-icon-only');
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          document.body.classList.toggle('sidebar-icon-only');
          el.classList.remove('hover-open');
        }
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach((i) => {
      if (i !== 'func') this.setState({ [i]: false });
    });
    const dropdownPaths = [
      { path: '/settings', state: 'settingsMenuOpen' },
      { path: '/company', state: 'companyMenuOpen' },
      { path: '/employee', state: 'employeeMenuOpen' },
      { path: '/roaster', state: 'roasterMenuOpen' },
      { path: '/leave', state: 'mangeMenuOpen' },
      { path: '/role', state: 'roleMenuOpen' },
      { path: '/referral', state: 'referralMenuOpen' },
      { path: '/pharmacy', state: 'pharmacyMenuOpen' },
      { path: '/lab', state: 'labMenuOpen' },
      { path: '/master', state: 'masterMenuOpen' },
      { path: '/service', state: 'serviceMenuOpen' },
      { path: '/slot', state: 'slotMenuOpen' },
      { path: '/booking', state: 'bookingMenuOpen' },
      { path: '/skill', state: 'skillMenuOpen' },
      { path: '/coupons', state: 'couponMenuOpen' },
      { path: '/patient', state: 'patientprofileMenu' },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  getLinkAccess(funcName) {
    const { func } = this.state;
    return func && func.includes(funcName);
  }

  getLinkHeadAccess(links) {
    const { func } = this.state;
    return func && func.some((item) => links.includes(item));
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title">Home</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          {/* <div className="sidebar-nav-item-head-title">
            <span className="menu-title">
              Management <i className="mdi mdi-arrow-down menu-icon-for-title"></i>
            </span>
          </div> */}

          {/* Category */}
          {/* {this.getLinkHeadAccess(['MANAGE_CATEGORY_TYPE', 'MANAGE_CATEGORY', 'MANAGE_SPECIALITY_ORDER']) && (
            <li className={this.isPathActive('/settings') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.settingsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('settingsMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Category</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi mdi-settings menu-icon"></i>
              </div>
              <Collapse in={this.state.settingsMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_CATEGORY_TYPE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/settings/category-type') ? 'nav-link active' : 'nav-link'}
                        to="/settings/category-type"
                      >
                        Create Type
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_CATEGORY') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/settings/category') ? 'nav-link active' : 'nav-link'}
                        to="/settings/category"
                      >
                        Create Item
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SPECIALITY_ORDER') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/settings/category-order') ? 'nav-link active' : 'nav-link'}
                        to="/settings/category-order"
                      >
                        Item Order
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}

          {/* Hospital */}
          {/* {this.getLinkHeadAccess(['MANAGE_COMPANY', 'MANAGE_BRANCH']) && (
            <li className={this.isPathActive('/company') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.companyMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('companyMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Hospital</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-hospital-building menu-icon"></i>
              </div>
              <Collapse in={this.state.companyMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_COMPANY') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/company/company') ? 'nav-link active' : 'nav-link'}
                        to="/company/company"
                      >
                        Create Hospital
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_BRANCH') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/company/branch') ? 'nav-link active' : 'nav-link'}
                        to="/company/branch"
                      >
                        Create Branch
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}

          {/* Create Competence */}
          {/* {this.getLinkHeadAccess([
            'MANAGE_SKILL_TYPES',
            'MANAGE_SKILL_LEVELS',
            'MANAGE_SKILL_SETS',
            'MANAGE_SKILL_MAPS',
          ]) && (
            <li className={this.isPathActive('/skill') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.skillMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('skillMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Create Competence</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-math-compass menu-icon"></i>
              </div>
              <Collapse in={this.state.skillMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_SKILL_TYPES') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/skill/types') ? 'nav-link active' : 'nav-link'}
                        to="/skill/types"
                      >
                        Types
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SKILL_LEVELS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/skill/levels') ? 'nav-link active' : 'nav-link'}
                        to="/skill/levels"
                      >
                        Levels
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SKILL_SETS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/skill/sets') ? 'nav-link active' : 'nav-link'}
                        to="/skill/sets"
                      >
                        Sets
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SKILL_MAPS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/skill/maps') ? 'nav-link active' : 'nav-link'}
                        to="/skill/maps"
                      >
                        Maps
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}

          {/* Employment */}
          {/* {this.getLinkHeadAccess(['MANAGE_EMPLOYEE']) && (
            <li className={this.isPathActive('/employee') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.employeeMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('employeeMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Employment</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-account menu-icon"></i>
              </div>
              <Collapse in={this.state.employeeMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_EMPLOYEE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/employee/manage-employee') ? 'nav-link active' : 'nav-link'}
                        to="/employee/manage-employee"
                      >
                        Manage Employee
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}

          {/* Roles */}
          {/* {this.getLinkHeadAccess(['MANAGE_ROLE_MANAGEMENT', 'MANAGE_ROLES', 'MANAGE_ROLE_MAPPING']) && (
            <li className={this.isPathActive('/role') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.roleMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('roleMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Roles</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-account menu-icon"></i>
              </div>
              <Collapse in={this.state.roleMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_ROLES') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/role/roles') ? 'nav-link active' : 'nav-link'}
                        to="/role/roles"
                      >
                        View Roles
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_ROLE_MANAGEMENT') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/role/responsibility') ? 'nav-link active' : 'nav-link'}
                        to="/role/responsibility"
                      >
                        Add Responsibilities
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_ROLE_MAPPING') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/role/role-mapping') ? 'nav-link active' : 'nav-link'}
                        to="/role/role-mapping"
                      >
                        Assign Role
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}

          {/* Referral */}
          {/* {this.getLinkHeadAccess(['MANAGE_REFERRAL_MAPPING', 'MANAGE_REFERRAL_REQUEST']) && (
            <li className={this.isPathActive('/referral') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.referralMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('referralMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Referral</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-account menu-icon"></i>
              </div>
              <Collapse in={this.state.referralMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_REFERRAL_MAPPING') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/referral/referral') ? 'nav-link active' : 'nav-link'}
                        to="/referral/referral"
                      >
                        Referral
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_REFERRAL_MAPPING') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/referral/referral-mapping') ? 'nav-link active' : 'nav-link'}
                        to="/referral/referral-mapping"
                      >
                        Referral Mapping
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_REFERRAL_REQUEST') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/referral/referral-request') ? 'nav-link active' : 'nav-link'}
                        to="/referral/referral-request"
                      >
                        Referral Request
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}
          {/* roaster */}
          {/* {this.getLinkHeadAccess([
            'MANAGE_SHIFT_TIME',
            'MANAGE_SHIFT_MAPPING',
            'MANAGE_WEEK_OFF',
            'MANAGE_DOCTOR_CALENDER',
            'MANAGE_SLOT_SETTINGS',
            'MANAGE_LEAVE_SETTINGS',
          ]) && (
            <li className={this.isPathActive('/roaster') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.roasterMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('roasterMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Shift Roaster</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi mdi-timetable menu-icon"></i>
              </div>
              <Collapse in={this.state.roasterMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_SHIFT_TIME') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/roaster/shift-time') ? 'nav-link active' : 'nav-link'}
                        to="/roaster/shift-time"
                      >
                        Shift Time
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SHIFT_MAPPING') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/roaster/shift-mapping') ? 'nav-link active' : 'nav-link'}
                        to="/roaster/shift-mapping"
                      >
                        Assign Shift
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_WEEK_OFF') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/roaster/week-off') ? 'nav-link active' : 'nav-link'}
                        to="/roaster/week-off"
                      >
                        Week Off
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DOCTOR_CALENDER') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/roaster/doctor-calender') ? 'nav-link active' : 'nav-link'}
                        to="/roaster/doctor-calender"
                      >
                        Doctor Calender
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SLOT_SETTINGS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/roaster/slot') ? 'nav-link active' : 'nav-link'}
                        to="/roaster/slot"
                      >
                        Slot Settings
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}

          {/* Leave Manage Absence */}
          {/* <li className={this.isPathActive('/leave') ? 'nav-item active' : 'nav-item'}>
            <div
              className={this.state.mangeMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
              onClick={() => this.toggleMenuState('mangeMenuOpen')}
              data-toggle="collapse"
            >
              <span className="menu-title">Manage Absence</span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi mdi-account-alert menu-icon"></i>
            </div>
            <Collapse in={this.state.mangeMenuOpen}>
              <ul className="nav flex-column sub-menu">
                {this.getLinkAccess('MANAGE_CATEGORY_TYPE') && (
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={this.isPathActive('/leave/addleave') ? 'nav-link active' : 'nav-link'}
                      to="/leave/addleave"
                    >
                      Apply Leave
                    </Link>
                  </li>
                )}
                {this.getLinkAccess('MANAGE_CATEGORY') && (
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={this.isPathActive('/leave/histroy') ? 'nav-link active' : 'nav-link'}
                      to="/leave/histroy"
                    >
                      View Leave Request
                    </Link>
                  </li>
                )}
                {this.getLinkAccess('MANAGE_SPECIALITY_ORDER') && (
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={this.isPathActive('/leave/balance') ? 'nav-link active' : 'nav-link'}
                      to="/leave/balance"
                    >
                      Leave Balance
                    </Link>
                  </li>
                )}
              </ul>
            </Collapse>
          </li> */}

          {/* pharmacy */}
          {/* {this.getLinkHeadAccess([
            'MANAGE_PHARMACY',
            'MANAGE_DRUG_PACK',
            'MANAGE_DRUG_PRODUCT_TYPE',
            'MANAGE_DRUG_SCHEDULE',
            'MANAGE_DRUG_TYPE',
            'MANAGE_DRUG',
          ]) && (
            <li className={this.isPathActive('/pharmacy') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.pharmacyMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('pharmacyMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Pharmacy</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-hospital-building menu-icon"></i>
              </div>
              <Collapse in={this.state.pharmacyMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_DRUG_PACK') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/pharmacy/drug-pack') ? 'nav-link active' : 'nav-link'}
                        to="/pharmacy/drug-pack"
                      >
                        Drug Pack
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG_PRODUCT_TYPE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/pharmacy/drug-product-type') ? 'nav-link active' : 'nav-link'}
                        to="/pharmacy/drug-product-type"
                      >
                        Drug Product Type
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG_SCHEDULE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/pharmacy/drug-schedule') ? 'nav-link active' : 'nav-link'}
                        to="/pharmacy/drug-schedule"
                      >
                        Drug Schedule
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG_TYPE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/pharmacy/drug-type') ? 'nav-link active' : 'nav-link'}
                        to="/pharmacy/drug-type"
                      >
                        Drug Type
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DRUG') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/pharmacy/drug') ? 'nav-link active' : 'nav-link'}
                        to="/pharmacy/drug"
                      >
                        Drug
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}
          {this.getLinkHeadAccess(['MANAGE_LAB_DETAILS']) && (
            <li className={this.isPathActive('/lab') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.labMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('labMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Laboratory</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-hospital-building menu-icon"></i>
              </div>
              <Collapse in={this.state.labMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_LAB_DETAILS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/lab-details') ? 'nav-link active' : 'nav-link'}
                        to="/lab/lab-details"
                      >
                        Lab Details
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_LAB_DETAILS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/external-labs') ? 'nav-link active' : 'nav-link'}
                        to="/lab/external-labs"
                      >
                        External Lab
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_LAB_DETAILS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/external') ? 'nav-link active' : 'nav-link'}
                        to="/lab/external"
                      >
                        External Lab Priority
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_LAB_DETAILS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/externalLabPackageMapping') ? 'nav-link active' : 'nav-link'}
                        to="/lab/externalLabMapping"
                      >
                        External Lab Mapping
                      </Link>
                    </li>
                  )}
                  {/* {this.getLinkAccess('MANAGE_LAB_DETAILS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/packageMapping') ? 'nav-link active' : 'nav-link'}
                        to="/lab/packageMapping"
                      >
                        Package Mapping
                      </Link>
                    </li>
                  )} */}
                </ul>
              </Collapse>
            </li>
          )}
          {this.getLinkHeadAccess(['MANAGE_UNIT', 'MANAGE_GROUP', 'MANAGE_SUBGROUP', 'MANAGE_TESTS']) && (
            <li className={this.isPathActive('/master') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.masterMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('masterMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Masters</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-database-plus menu-icon"></i>
              </div>
              <Collapse in={this.state.masterMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_UNIT') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/master/unit') ? 'nav-link active' : 'nav-link'}
                        to="/master/unit"
                      >
                        Unit
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_UNIT') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/master/sampleMaster') ? 'nav-link active' : 'nav-link'}
                        to="/master/sampleMaster"
                      >
                        Sample Masters
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_GROUP') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/master/groups') ? 'nav-link active' : 'nav-link'}
                        to="/master/groups"
                      >
                        Group
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SUBGROUP') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/master/subgroups') ? 'nav-link active' : 'nav-link'}
                        to="/master/subgroups"
                      >
                        Subgroup
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_TESTS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/master/tests') ? 'nav-link active' : 'nav-link'}
                        to="/master/tests"
                      >
                        Tests
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_GROUP') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/master/group/priority') ? 'nav-link active' : 'nav-link'}
                        to="/master/group/priority"
                      >
                        Group Priority Order
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SUBGROUP') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/master/subgroup/priority') ? 'nav-link active' : 'nav-link'}
                        to="/master/subgroup/priority"
                      >
                        Subgroup Priority Order
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_TESTS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/master/test/priority') ? 'nav-link active' : 'nav-link'}
                        to="/master/test/priority"
                      >
                        Test Priority Order
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {this.getLinkHeadAccess(['MANAGE_SERVICE_PINCODE', 'MANAGE_SERVICE_PINCODE_ORDER']) && (
            <li className={this.isPathActive('/service') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.serviceMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('serviceMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Services</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-server-plus menu-icon"></i>
              </div>
              <Collapse in={this.state.serviceMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_SERVICE_PINCODE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/service/service-pincode') ? 'nav-link active' : 'nav-link'}
                        to="/service/service-pincode"
                      >
                        Service Pincode
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SERVICE_PINCODE_ORDER') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/service/pincode-order') ? 'nav-link active' : 'nav-link'}
                        to="/service/pincode-order"
                      >
                        Service Pincode Order
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )}
          {this.getLinkHeadAccess(['MANAGE_LAB_SLOTS']) && (
            <li className={this.isPathActive('/slot') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.slotMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('slotMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Slot</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-timetable menu-icon"></i>
              </div>
              <Collapse in={this.state.slotMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_LAB_SLOTS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/slot/list') ? 'nav-link active' : 'nav-link'}
                        to="/slot/list"
                      >
                        Lab Slot
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_LAB_SLOTS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/slot/booked-slots') ? 'nav-link active' : 'nav-link'}
                        to="/slot/booked-slots"
                      >
                        Lab Booked Slot
                      </Link>
                    </li>
                  )}
                  {/* {this.getLinkAccess('MANAGE_LAB_SLOTS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/slot/bulk') ? 'nav-link active' : 'nav-link'}
                        to="/slot/bulk"
                      >
                        Bulk Slot
                      </Link>
                    </li>
                  )} */}
                </ul>
              </Collapse>
            </li>
          )}
          {this.getLinkAccess('MANAGE_DETAILS') && (
            <li className="nav-item">
              {' '}
              <Link className={this.isPathActive('/details') ? 'nav-link active' : 'nav-link'} to="/details">
                <span className="menu-title "> Lab Mapping</span>
                <i className="mdi mdi-map menu-icon"></i>
              </Link>
            </li>
          )}
          {this.getLinkAccess('MANAGE_PACKAGE') && (
            <li className="nav-item">
              {' '}
              <Link className={this.isPathActive('/package') ? 'nav-link active' : 'nav-link'} to="/package">
                <span className="menu-title "> Package</span>
                <i className="mdi mdi-package menu-icon"></i>
              </Link>
            </li>
          )}
          {this.getLinkHeadAccess(['MANAGE_LAB_BOOKINGS']) && (
            <li className={this.isPathActive('/booking') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.bookingMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('bookingMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Lab Bookings</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-library menu-icon"></i>
              </div>
              <Collapse in={this.state.bookingMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={this.isPathActive('/booking/bookings/list') ? 'nav-link active' : 'nav-link'}
                      to="/booking/bookings/list"
                    >
                      Bookings
                    </Link>
                  </li>

                  <li className="nav-item">
                    {' '}
                    <Link
                      className={this.isPathActive('/booking/bookingstatus') ? 'nav-link active' : 'nav-link'}
                      to="/booking/bookingstatus"
                    >
                      Booking Status
                    </Link>
                  </li>
                  <li className="nav-item">
                    {' '}
                    <Link
                      className={this.isPathActive('/booking/report') ? 'nav-link active' : 'nav-link'}
                      to="/booking/report"
                    >
                      OI Report Upload
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </li>
          )}
          {/* {this.getLinkHeadAccess([
            'MANAGE_LAB_DETAILS',
            'MANAGE_LAB_SLOTS',
            'MANAGE_UNIT',
            'MANAGE_GROUP',
            'MANAGE_SUBGROUP',
            'MANAGE_TESTS',
            'MANAGE_PACKAGE',
            'MANAGE_DETAILS',
            'MANAGE_SERVICE_PINCODE',
            'MANAGE_SERVICE_PINCODE_ORDER',
          ]) && (
            <li className={this.isPathActive('/lab') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.labMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('lbMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Lab</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-hospital-building menu-icon"></i>
              </div>
              <Collapse in={this.state.labMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_LAB_DETAILS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/lab-details') ? 'nav-link active' : 'nav-link'}
                        to="/lab/lab-details"
                      >
                        Lab Details
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_LAB_DETAILS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/external') ? 'nav-link active' : 'nav-link'}
                        to="/lab/external"
                      >
                        External Lab Priority className=
                        {this.isPathActive('/lab/external-labs') ? 'nav-link active' : 'nav-link'}
                        to="/lab/external-labs" > External Lab >>>>>>> release/R2202
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SERVICE_PINCODE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/service-pincode') ? 'nav-link active' : 'nav-link'}
                        to="/lab/service-pincode"
                      >
                        Service Pincode
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SERVICE_PINCODE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/group/priority') ? 'nav-link active' : 'nav-link'}
                        to="/lab/group/priority"
                      >
                        Group Priority Order
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SERVICE_PINCODE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/subgroup/priority') ? 'nav-link active' : 'nav-link'}
                        to="/lab/subgroup/priority"
                      >
                        Subgroup Priority Order
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SERVICE_PINCODE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/test/priority') ? 'nav-link active' : 'nav-link'}
                        to="/lab/test/priority"
                      >
                        Test Priority Order
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SERVICE_PINCODE_ORDER') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/pincode-order') ? 'nav-link active' : 'nav-link'}
                        to="/lab/pincode-order"
                      >
                        Service Pincode Order
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_LAB_SLOTS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/slots') ? 'nav-link active' : 'nav-link'}
                        to="/lab/slots"
                      >
                        Lab Slots
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_LAB_SLOTS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/booked-slots') ? 'nav-link active' : 'nav-link'}
                        to="/lab/booked-slots"
                      >
                        Lab Booked Slots
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_UNIT') && (
                    <li className="nav-item">
                      {' '}
                      <Link className={this.isPathActive('/lab/unit') ? 'nav-link active' : 'nav-link'} to="/lab/unit">
                        Unit
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_UNIT') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/sampleMaster') ? 'nav-link active' : 'nav-link'}
                        to="/lab/sampleMaster"
                      >
                        Sample Masters
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_GROUP') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/group') ? 'nav-link active' : 'nav-link'}
                        to="/lab/group"
                      >
                        Group
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_SUBGROUP') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/subgroup') ? 'nav-link active' : 'nav-link'}
                        to="/lab/subgroup"
                      >
                        Subgroup
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_TESTS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/tests') ? 'nav-link active' : 'nav-link'}
                        to="/lab/tests"
                      >
                        Tests
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_DETAILS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/details') ? 'nav-link active' : 'nav-link'}
                        to="/lab/details"
                      >
                        Lab Mapping
                      </Link>
                    </li>
                  )}
                  {this.getLinkAccess('MANAGE_PACKAGE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/package') ? 'nav-link active' : 'nav-link'}
                        to="/lab/package"
                      >
                        Package
                      </Link>
                    </li>
                  )}

                  <li className="nav-item">
                    {' '}
                    <Link
                      className={this.isPathActive('/lab/bookings') ? 'nav-link active' : 'nav-link'}
                      to="/lab/bookings"
                    >
                      Bookings
                    </Link>
                  </li>
                  {this.getLinkAccess('MANAGE_DETAILS') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/lab/bookingstatus') ? 'nav-link active' : 'nav-link'}
                        to="/lab/bookingstatus"
                      >
                        Booking Status
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}
          <li className={this.isPathActive('/faqs') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/faqs">
              <span className="menu-title "> FAQs</span>
              <i className="mdi mdi-help-circle-outline menu-icon"></i>
            </Link>
          </li>

          <li className={this.isPathActive('/privacy') ? 'nav-item active' : 'nav-item'}>
            {' '}
            <Link className="nav-link" to="/privacy">
              <span className="menu-title"> Privacy Policy</span>
              <i className="mdi mdi-account-key menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/conditions') ? 'nav-item active' : 'nav-item'}>
            {' '}
            <Link className="nav-link" to="/conditions">
              <span className="menu-title"> Terms and Conditions</span>
              <i className="mdi mdi-scale-balance menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/needhelp') ? 'nav-item active' : 'nav-item'}>
            {' '}
            <Link className="nav-link" to="/needhelp">
              <span className="menu-title"> Need Help ?</span>
              <i className="mdi mdi-help-box menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/changepassword') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/changepassword">
              <span className="menu-title"> Change Password</span>
              <i className="mdi mdi-lock menu-icon"></i>
            </Link>
          </li>
          {/* <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title">Home</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li> */}
          {/* Coupons */}
          {/* {this.getLinkHeadAccess(['MANAGE_COUPONS']) && (
            <li className={this.isPathActive('/coupons') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.couponMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('couponMenuOpen')}
                data-toggle="collapse"
              >
                <span className="menu-title">Coupons</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-ticket-percent menu-icon"></i>
              </div>
              <Collapse in={this.state.couponMenuOpen}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_COUPONS') && (
                    <li className="nav-item">
                      {' '}
                      <Link className={this.isPathActive('/coupons') ? 'nav-link active' : 'nav-link'} to="/coupons">
                        Coupons
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}
          {/* {this.getLinkHeadAccess(['MANAGE_PATIENT_PROFILE']) && (
            <li className={this.isPathActive('/patient') ? 'nav-item active' : 'nav-item'}>
              <div
                className={this.state.patientprofileMenu ? 'nav-link menu-expanded' : 'nav-link'}
                onClick={() => this.toggleMenuState('patientprofileMenu')}
                data-toggle="collapse"
              >
                <span className="menu-title">Patients</span>
                <i className="menu-arrow"></i>
                <i className="mdi mdi-account-card-details menu-icon"></i>
              </div>
              <Collapse in={this.state.patientprofileMenu}>
                <ul className="nav flex-column sub-menu">
                  {this.getLinkAccess('MANAGE_PATIENT_PROFILE') && (
                    <li className="nav-item">
                      {' '}
                      <Link
                        className={this.isPathActive('/patient/profile') ? 'nav-link active' : 'nav-link'}
                        to="/patient/profile"
                      >
                        Profile
                      </Link>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
          )} */}
        </ul>
      </nav>
    );
  }
}

Sidebar.propTypes = {
  location: PropTypes.any,
  func: PropTypes.array,
};
export default withRouter(Sidebar);
