import { toast } from 'react-toastify';

export const getToken = () => {
  return localStorage.getItem('accessToken');
};
export const removeToken = () => {
  localStorage.clear();
};
export const getloginDuration = () => {
  const time = localStorage.getItem('loginDuration');
  const sec = time * 60000;
  console.log(sec, 'sec');
  return sec;
};
export const getUsername = () => {
  return localStorage.getItem('username');
};
export const getfunctions = () => {
  return localStorage.getItem('functions');
};
export const getlabcode = () => {
  return localStorage.getItem('labcode');
};
export const getlabname = () => {
  return localStorage.getItem('labname');
};
export const handleWarnAlert = (reason) => {
  toast.warn(reason, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};
export const handleGoogAlert = (reason) => {
  toast.success(reason, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};
export const faqLab = [
  {
    id: 1,
    title: 'If you’ve never used the app before, what lab tests do you need to perform before your first visit?',
    content:
      'Before the appointment, the physician assistant will gather your medical history and order the lab tests you need. ',
  },
  {
    id: 2,
    title: 'How much do the lab tests cost?',
    content: 'The app displays the cost of each lab test separately, so you always know what to expect. ',
  },
  {
    id: 3,
    title: 'Do you have the option to pick the date and time of home testing?',
    content:
      'Currently, this feature is not available. Once you order the lab tests, a lab executive will contact you to schedule your blood collection.',
  },
  {
    id: 4,
    title: 'How do you pay for your lab tests?',
    content:
      'After your first consultation, the tests ordered by the doctor will appear under the “Lab Tests” tab. \n ◉ If you click the “Place Order” button, you will be taken to the lab test pricing page.  \n ◉Here, you can also edit the address where you want the blood collection to be done. \n ◉Upon confirmation, you’ll be directed to a third-party payment gateway. You can pay with a credit card, debit card, UPI, QR code, Wallet, Net banking and Pay Later. ',
  },
  {
    id: 5,
    title: 'Do you have the option to pick and choose which lab tests to perform?',
    content:
      'We do not offer this feature. In accordance with the doctor’s instructions, the PA orders the tests. It is therefore necessary to perform all the tests throughout. In any case, the PA can explain why certain tests are required if necessary.',
  },
  {
    id: 6,
    title: 'Who will collect your blood sample?',
    content: 'An official from Diahome will pick up your blood samples at a time convenient to you. ',
  },
  {
    id: 7,
    title: 'Will you be notified about who will pick up your blood samples?',
    content:
      'Yes, the lab executive will send notifications and reminders with all the necessary information, like the executive’s name, phone number, and photo. ',
  },
  {
    id: 8,
    title: 'What cities are currently offering Home Lab tests?',
    content:
      'Besicontent home blood collection, other measurements such as height, weight, waist circumference, blood pressure, and pulse (anthropometry measurements) are currently available in Chennai, Coimbatore, Trichy, Bengaluru, New Delhi, Hyderabad, Mumbai, Kolkata, Ahmedabad, Pune, and Jaipur.  Blood collection is only available in Pondicherry, Madurai, Salem, Vellore, Erode, Tirupur, Pollachi, Karur, Dharapuram, Dharmapuri, Oddanchatram, Dindigul, Palani, Cuddalore, Sholinghur, Ranipet, Arani, Arcot, Cheyyar, Vandavasi, Tiruvannamalai, Virudhachalam, and Calicut.',
  },
  {
    id: 9,
    title: 'What procedures should you follow before and after the collection of your blood?',
    content:
      'A fast of at least eight hours is required before blood collection.  \n ◉While fasting, you may only drink water. \n ◉After blood collection, you can break your fast and take your regular medication after food intake. \n ◉A postprandial blood collection is performed one hour and thirty minutes after you break your fast.  \n ◉Breakfast is recommended immediately after blood collection. ',
  },
  {
    id: 10,
    title: 'If the collection person does not arrive on time, what should you do? ',
    content:
      'The collection executive’s phone number would be provided to you\n ◉To find out when they are coming, you may call them.  \n ◉If they are not reachable, you can call our helpline number.  \n ◉We will reschedule the collection for a later date if the collection executive arrives 30 minutes later than the scheduled time.\n ◉Blood test results will be ready by the end of the day.\n ◉Before your consultation, it will be uploaded to the Diahome app.\n ◉You can reschedule the date and time by calling the helpline number.\n ◉Should the doctor’s scheduled appointment change, the physician assistant will call you and ask to reschedule.       ',
  },
  {
    id: 11,
    title: 'When will you get your blood report?',
    content:
      'Once the blood has been collected, blood reports are ready by the end of the day. Before your consultation, your doctor will be able to see your test results on the app. ',
  },
  {
    id: 12,
    title: 'Is it possible to reschedule the blood collection time and date?',
    content: 'Is it possible to reschedule the blood collection time and date?',
  },
  {
    id: 13,
    title: 'Is it possible to reschedule the blood collection time and date?',
    content:
      'By calling the helpline number, you can reschedule the date and time. Please check with your doctor’s assistant in case the appointment time gets changed. ',
  },
];
