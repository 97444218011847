import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { getToken } from './utils/utils';

import Spinner from './components/Spinner';

const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const AdminDashboard = lazy(() => import('./pages/dashboard/AdminDashboard'));

// Category
// const Category = lazy(() => import('./pages/settings/category/Category'));
// const CategoryOrder = lazy(() => import('./pages/settings/category-order/CategoryOrder'));
// const CategoryType = lazy(() => import('./pages/settings/category-type/CategoryType'));

// const Department = lazy(() => import('./pages/settings/department/Department'));
// const AddDepartment = lazy(() => import('./pages/settings/department/AddDepartment'));

// const Designation = lazy(() => import('./pages/settings/designation/Designation'));
// const AddDesignation = lazy(() => import('./pages/settings/designation/AddDesignation'));

// const Speciality = lazy(() => import('./pages/settings/speciality/Speciality'));
// const AddSpeciality = lazy(() => import('./pages/settings/speciality/AddSpeciality'));

// const Course = lazy(() => import('./pages/settings/course/Course'));
// const AddCourse = lazy(() => import('./pages/settings/course/AddCourse'));

// const EducationLevels = lazy(() => import('./pages/settings/education/EducationLevels'));
// const AddEducationLevel = lazy(() => import('./pages/settings/education/AddEducationLevel'));

// const Assets = lazy(() => import('./pages/settings/assets/Assets'));
// const AddAsset = lazy(() => import('./pages/settings/assets/AddAsset'));

// const EmployeeType = lazy(() => import('./pages/settings/employee-type/EmployeeType'));
// const AddEmployeeType = lazy(() => import('./pages/settings/employee-type/AddEmployeeType'));

// const JobType = lazy(() => import('./pages/settings/job-type/JobType'));
// const AddJobType = lazy(() => import('./pages/settings/job-type/AddJobType'));

// const DocumentType = lazy(() => import('./pages/settings/document-type/DocumentType'));
// const AddDocumentType = lazy(() => import('./pages/settings/document-type/AddDocumentType'));

// Employee
// const Employee = lazy(() => import('./pages/employee/Employee'));
// const AddEmployee = lazy(() => import('./pages/employee/AddEmployee'));

const Profile = lazy(() => import('./pages/profile/Profile'));

// roaster
// const Shift = lazy(() => import('./pages/roaster/shift/Shift'));
// const AddShift = lazy(() => import('./pages/roaster/shift/AddShift'));
// const ShiftTime = lazy(() => import('./pages/roaster/shift-time/ShiftTime'));
// const AddShiftTime = lazy(() => import('./pages/roaster/shift-time/AddShiftTime'));
// const ShiftMapping = lazy(() => import('./pages/roaster/shift-mapping/ShiftMapping'));
// const AddShiftMapping = lazy(() => import('./pages/roaster/shift-mapping/AddShiftMapping'));
// const WeekOff = lazy(() => import('./pages/roaster/week-off/WeekOff'));
// const AddWeekOff = lazy(() => import('./pages/roaster/week-off/AddWeekOff'));
// const DoctorCalender = lazy(() => import('./pages/roaster/doctor-calender/DoctorCalender'));
// const AddSlot = lazy(() => import('./pages/roaster/slot/AddSlot'));
// const Slot = lazy(() => import('./pages/roaster/slot/Slot'));

// Hospital
// const AddCompany = lazy(() => import('./pages/company/company/AddCompany'));
// const Company = lazy(() => import('./pages/company/company/Company'));
// const AddBranch = lazy(() => import('./pages/company/branch/AddBranch'));
// const Branch = lazy(() => import('./pages/company/branch/Branch'));

// Leave
// const Leave = lazy(() => import('./pages/roaster/leave/Leave'));
// const AddLeave = lazy(() => import('./pages/roaster/leave/Addleave'));
// const Leaverequest = lazy(() => import('./pages/roaster/leave/Leaverequest'));

// Roles
// const Roles = lazy(() => import('./pages/role-management/role-mapping/Roles'));
// const RolesResponsibility = lazy(() => import('./pages/role-management/role-mapping/RolesResponsibility'));
// const RoleMapping = lazy(() => import('./pages/role-management/role-mapping/RoleMapping'));

// Referral
// const Referral = lazy(() => import('./pages/referral/Referral'));
// const AddReferral = lazy(() => import('./pages/referral/AddReferral'));
// const ReferralMapping = lazy(() => import('./pages/referral/ReferralMapping'));
// const AddReferralMapping = lazy(() => import('./pages/referral/AddReferralMapping'));
// const ReferralRequest = lazy(() => import('./pages/referral/ReferralRequest'));

// const ReferralLink = lazy(() => import('./pages/refer-link/ReferLink'));
// const ReferralDoctor = lazy(() => import('./pages/refer-doctor/ReferDoctor'));

// pharmacy
// const PharmaDrugType = lazy(() => import('./pages/pharmacy/drug-type/DrugType'));
// const PharmaPack = lazy(() => import('./pages/pharmacy/pack/Pack'));
// const PharmaProductType = lazy(() => import('./pages/pharmacy/product-type/ProductType'));
// const PharmaSchedule = lazy(() => import('./pages/pharmacy/schedule/Schedule'));
// const PharmaDrug = lazy(() => import('./pages/pharmacy/drug/Drug'));

const LabDetails = lazy(() => import('./pages/lab/lab-details/LabDetails'));
const ExternalLabAPI = lazy(() => import('./pages/lab/lab-details/ExternalLabpriority'));
const ExternalLab = lazy(() => import('./pages/lab/externalLab/ExtenalLab'));
const ExternalLabMapping = lazy(() => import('./pages/lab/externalLab/ExternalLabMapping'));
const ExternalLabPackageMapping = lazy(() => import('./pages/lab/externalLab/ExternalLabPackageMapping'));

const Grouppre = lazy(() => import('./pages/lab/lab-details/Grouppriority'));
const SubGrouporder = lazy(() => import('./pages/lab/lab-details/Subgrouporder'));
const Testorder = lazy(() => import('./pages/lab/lab-details/Testorder'));
const LabUnit = lazy(() => import('./pages/lab/Unit'));
const LabSampleMaster = lazy(() => import('./pages/lab/SampleMaster'));
const LabGroup = lazy(() => import('./pages/lab/Group'));
const LabSubGroup = lazy(() => import('./pages/lab/SubGroup'));
const LabTests = lazy(() => import('./pages/lab/Tests'));
const LabPackage = lazy(() => import('./pages/lab/Package'));
const Details = lazy(() => import('./pages/lab/Details'));
const LabPincode = lazy(() => import('./pages/lab/Pincode'));
const LabPincodeOrder = lazy(() => import('./pages/lab/PincodeOrder'));
const LabSlots = lazy(() => import('./pages/lab/lab-details/LabSlots'));
const BulkSlots = lazy(() => import('./pages/lab/lab-details/Bulklabslot'));
const BookedSlots = lazy(() => import('./pages/lab/lab-details/BookedSlots'));
const LabBookings = lazy(() => import('./pages/lab/Bookings'));
const BookingStatus = lazy(() => import('./pages/lab/BookingStatus'));
const ReportPage = lazy(() => import('./pages/lab/lab-details/UploadLabReport'));
const TestReport = lazy(() => import('./pages/lab/TestReport'));
const FAQs = lazy(() => import('./pages/lab/Faqs'));
const Privacy = lazy(() => import('./pages/lab/Privacy'));
const Need = lazy(() => import('./pages/lab/Needhelp'));
const Conditions = lazy(() => import('./pages/lab/Conditions'));
const Changepassword = lazy(() => import('./pages/lab/Changepassword'));

const LabSlotsTest = lazy(() => import('./pages/lab/lab-details/LabSlotsTest'));
// Create Competence
// const SkillTypes = lazy(() => import('./pages/skills/Skilltypes'));
// const SkillLevels = lazy(() => import('./pages/skills/SkillLevels'));
// const SkillSets = lazy(() => import('./pages/skills/SkillSets'));
// const SkillMaps = lazy(() => import('./pages/skills/SkillMaps'));

// Coupons
// const Coupons = lazy(() => import('./pages/coupons/Coupon'));

const PatientProfile = lazy(() => import('./pages/patient-profile/patientProfile'));

const Error404 = lazy(() => import('./pages/error-pages/Error404'));
const Error500 = lazy(() => import('./pages/error-pages/Error500'));

const Login = lazy(() => import('./pages/user-pages/Login'));

const BlankPage = lazy(() => import('./pages/general-pages/BlankPage'));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/admin/dashboard" component={AdminDashboard} />

          {/* Category */}
          {/* <Route exact path="/settings/category" component={Category} />
          <Route exact path="/settings/category-type" component={CategoryType} />
          <Route exact path="/settings/category-order" component={CategoryOrder} /> */}
          {/* <Route exact path="/settings/department" component={Department} />
          <Route exact path="/settings/add-department" component={AddDepartment} />
          <Route exact path="/settings/designation" component={Designation} />
          <Route exact path="/settings/add-designation" component={AddDesignation} />
          <Route exact path="/settings/assets" component={Assets} />
          <Route exact path="/settings/add-asset" component={AddAsset} />
          <Route exact path="/settings/course" component={Course} />
          <Route exact path="/settings/add-course" component={AddCourse} />
          <Route exact path="/settings/education-levels" component={EducationLevels} />
          <Route exact path="/settings/add-education-level" component={AddEducationLevel} />
          <Route exact path="/settings/document-type" component={DocumentType} />
          <Route exact path="/settings/add-document-type" component={AddDocumentType} />
          <Route exact path="/settings/job-type" component={JobType} />
          <Route exact path="/settings/add-job-type" component={AddJobType} />
          <Route exact path="/settings/employee-type" component={EmployeeType} />
          <Route exact path="/settings/add-employee-type" component={AddEmployeeType} />
          <Route exact path="/settings/speciality" component={Speciality} />
          <Route exact path="/settings/add-speciality" component={AddSpeciality} /> */}
          {/* Employee */}
          {/* <Route exact path="/employee/manage-employee" component={Employee} />
          <Route exact path="/employee/add-employee" component={AddEmployee} /> */}

          <Route exact path="/profile" component={Profile} />

          {/* roaster */}
          {/* <Route exact path="/roaster/add-shift" component={AddShift} />
          <Route exact path="/roaster/shift" component={Shift} />
          <Route exact path="/roaster/shift-time" component={ShiftTime} />
          <Route exact path="/roaster/add-shift-time" component={AddShiftTime} />
          <Route exact path="/roaster/add-shift-mapping" component={AddShiftMapping} />
          <Route exact path="/roaster/shift-mapping" component={ShiftMapping} />
          <Route exact path="/roaster/week-off" component={WeekOff} />
          <Route exact path="/roaster/add-week-off" component={AddWeekOff} />
          <Route exact path="/roaster/doctor-calender" component={DoctorCalender} />
          <Route exact path="/roaster/slot" component={Slot} />
          <Route exact path="/roaster/add-slot" component={AddSlot} /> */}

          {/* Leave */}
          {/* <Route exact path="/leave/balance" component={Leave} />
          <Route exact path="/leave/addleave" component={AddLeave} />
          <Route exact path="/leave/histroy" component={Leaverequest} /> */}

          {/* Hospital */}
          {/* <Route exact path="/company/add-company" component={AddCompany} />
          <Route exact path="/company/company" component={Company} />
          <Route exact path="/company/branch" component={Branch} />
          <Route exact path="/company/add-branch" component={AddBranch} /> */}

          {/* Roles */}
          {/* <Route exact path="/role/role-mapping" component={RoleMapping} />
          <Route exact path="/role/roles" component={Roles} />
          <Route exact path="/role/responsibility" component={RolesResponsibility} /> */}

          {/* <Route exact path="/referral/referral" component={Referral} />
          <Route exact path="/referral/add-referral" component={AddReferral} />
          <Route exact path="/referral/referral-mapping" component={ReferralMapping} />
          <Route exact path="/referral/add-referral-mapping" component={AddReferralMapping} />
          <Route exact path="/referral/referral-request" component={ReferralRequest} />
          <Route exact path="/referral-link" component={ReferralLink} />
          <Route exact path="/referral-doctor" component={ReferralDoctor} /> */}

          {/* pharmacy */}
          {/* <Route exact path="/pharmacy/drug" component={PharmaDrug} />
          <Route exact path="/pharmacy/drug-type" component={PharmaDrugType} />
          <Route exact path="/pharmacy/drug-pack" component={PharmaPack} />
          <Route exact path="/pharmacy/drug-product-type" component={PharmaProductType} />
          <Route exact path="/pharmacy/drug-schedule" component={PharmaSchedule} /> */}

          <Route exact path="/lab/lab-details" component={LabDetails} />
          <Route exact path="/lab/external" component={ExternalLabAPI} />
          <Route exact path="/master/group/priority" component={Grouppre} />
          <Route exact path="/master/subgroup/priority" component={SubGrouporder} />
          <Route exact path="/master/test/priority" component={Testorder} />
          <Route exact path="/master/unit" component={LabUnit} />
          <Route exact path="/master/sampleMaster" component={LabSampleMaster} />
          <Route exact path="/master/groups" component={LabGroup} />
          <Route exact path="/master/subgroups" component={LabSubGroup} />
          <Route exact path="/master/tests" component={LabTests} />
          <Route exact path="/package" component={LabPackage} />
          <Route exact path="/details" component={Details} />
          <Route exact path="/service/service-pincode" component={LabPincode} />
          <Route exact path="/service/pincode-order" component={LabPincodeOrder} />
          <Route exact path="/slot/list" component={LabSlots} />
          <Route exact path="/slot/bulk" component={BulkSlots} />
          <Route exact path="/slot/booked-slots" component={BookedSlots} />
          <Route exact path="/booking/bookings/list" component={LabBookings} />
          <Route exact path="/booking/bookingstatus" component={BookingStatus} />
          <Route exact path="/booking/report" component={ReportPage} />
          <Route exact path="/lab/external-labs" component={ExternalLab} />
          <Route exact path="/lab/externalLabMapping" component={ExternalLabMapping} />
          <Route exact path="/lab/packageMapping" component={ExternalLabPackageMapping} />
          <Route exact path="/lab/group/priority" component={Grouppre} />
          <Route exact path="/lab/subgroup/priority" component={SubGrouporder} />
          <Route exact path="/lab/test/priority" component={Testorder} />
          <Route exact path="/lab/unit" component={LabUnit} />
          <Route exact path="/lab/sampleMaster" component={LabSampleMaster} />
          <Route exact path="/lab/group" component={LabGroup} />
          <Route exact path="/lab/subgroup" component={LabSubGroup} />
          <Route exact path="/lab/tests" component={LabTests} />
          <Route exact path="/lab/package" component={LabPackage} />
          <Route exact path="/lab/details" component={Details} />
          <Route exact path="/lab/service-pincode" component={LabPincode} />
          <Route exact path="/lab/pincode-order" component={LabPincodeOrder} />
          <Route exact path="/lab/slots" component={LabSlots} />
          <Route exact path="/lab/booked-slots" component={BookedSlots} />
          <Route exact path="/lab/bookings" component={LabBookings} />
          <Route exact path="/lab/bookingstatus" component={BookingStatus} />
          <Route exact path="/lab/slotsTest" component={LabSlotsTest} />
          <Route exact path="/lab/testReport" component={TestReport} />
          <Route exact path="/faqs" component={FAQs} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/needhelp" component={Need} />
          <Route exact path="/conditions" component={Conditions} />
          <Route exact path="/changepassword" component={Changepassword} />

          {/* Create Competence */}
          {/* <Route exact path="/skill/types" component={SkillTypes} />
          <Route exact path="/skill/levels" component={SkillLevels} />
          <Route exact path="/skill/sets" component={SkillSets} />
          <Route exact path="/skill/maps" component={SkillMaps} /> */}

          {/* Coupons */}
          {/* <Route exact path="/coupons" component={Coupons} /> */}
          {/* COD */}

          <Route exact path="/patient/profile" component={PatientProfile} />

          <Route path="/login" component={Login} />

          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />

          <Route path="/general-pages/blank-page" component={BlankPage} />
          {getToken() ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
