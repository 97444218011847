import {
  login,
  getCategoryList,
  getHospital,
  getBranch,
  getCity,
  getState,
  getEmployee,
  listEmployee,
  getSlots,
  getSchedule,
  getReferral,
  getReferralMappings,
  getReferralRequest,
  getShifttime,
  getShiftTimeMap,
  getPharmaCategory,
  getAdminCategoryType,
  getAdminCategory,
  getLabData,
  getSubgroupByGroupId,
  getSkillsData,
  getCouponData,
  getRolesFunctionalityList,
  getRolesList,
  getPatientProfileData,
  getSlotDuration,
  getExecutiveByPincode,
  getLabAvailableSlotsByDate,
  getLabBookingDetails,
  getdashborad,
  postdashboard,
  admindash,
  countofdoc,
  countappoint,
  countlab,
  numberofpa,
  getAllTests,
  getPincode,
  getStateMaster,
  getCityMaster,
  getAllTestsTree,
  getLabPaginationData,
  getTestSampleService,
  getInhouseLabData,
  getExecutiveByservice,
  getPack,
  getgroup,
  getSubgroup,
  getExternalLabAllDataService,
  createExternalLabMappingService,
  getExternalLabByIDService,
  getAllLabData,
  getExternalProductService,
  getTemplateStatusTypeService,
  getUploadTemplateStatusService,
  getExternalLabData,
  recordOfLoginandLogout,
  getServicePincodeByLab,
  getAllLabBookingStatusSearch,
} from '../../services/services';
export const loadingAction = (loading) => async (dispatch, getState) => {
  dispatch({
    type: 'GLOBLE_OVERLOADING',
    payload: loading,
  });
};
export const submitButtonAction = (btnType) => async (dispatch, getState) => {
  dispatch({
    type: 'SUBMIT_BUTTON',
    payload: btnType,
  });
};
export const loginAction = (d, props) => async (dispatch, getState) => {
  dispatch(loadingAction(true));
  const res = await login(d, dispatch, props);
  if (res) {
    await recordOfLoginandLogout({ empId: res.data.basicProfile.empId, entryType: 'login' });
    dispatch({
      type: 'LOGIN',
      payload: res.data,
    });
    if (res.status === 200 && res.data.firstLogin === false) {
      props.history.push('/dashboard');
    }
    dispatch(loadingAction(false));
  }
};

export const getCategory = (_c, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  let categoryList = [];
  if (_c) {
    categoryList = _c;
  } else {
    categoryList = [
      'Title',
      'BloodGroup',
      'EmployeeType',
      'JobType',
      'SalaryType',
      'EducationLevel',
      'Gender',
      'Designation',
      'Specialists',
      'CourseList',
      'Department',
      'Language',
    ];
  }

  categoryList.map(async (c) => {
    const data = await getCategoryList(c, props);
    if (data && data.items && data.items.length > 0) {
      dispatch({
        type: 'GET_CATEGORY',
        payload: data,
        name: c.toLowerCase(),
      });
    }
    dispatch(loadingAction(false));
  });
};
export const loadingPage = (loadingpage) => async (dispatch, getState) => {
  dispatch({
    type: 'GLOBLE_LOADING',
    payload: loadingpage,
  });
};
export const getHospitalData = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getHospital();
  switch (data.code) {
    case '200':
      if (data && data.data && data.data.length > 0) {
        dispatch({
          type: 'GET_HOSPITAL_DATA',
          payload: data.data,
        });
      }
      dispatch(loadingAction(false));
      break;
    case 401:
      dispatch({
        type: 'SET_TOKEN_VALID',
        payload: false,
      });
      dispatch(loadingAction(false));
      break;
    default:
      break;
  }
};
export const getBranchData = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getBranch();
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_BRANCH_DATA',
      payload: data,
    });
  }
  dispatch(loadingAction(false));
};
export const getStateMasterData = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getStateMaster(props);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_STATE_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_STATE_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getStateData = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getState(props);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_STATE_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_STATE_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getCityDataMaster = (state, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getCityMaster(state, props);
  console.log(data);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_CITY_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_CITY_DATA',
      payload: {},
    });
    dispatch(loadingAction(false));
  }
};
export const getCityData = (state, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getCity(state, props);
  console.log(data);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_CITY_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_CITY_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getPinCodeData = (state, city, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getPincode(state, city, props);
  console.log(data);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_PINCODE_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_PINCODE_DATA',
      payload: {},
    });
    dispatch(loadingAction(false));
  }
};
export const getEmployeeData = (status) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getEmployee(status);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_Employee_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_Employee_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getDoctorData = (d, roles, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const filter = {
    searchCriteria: {
      role: roles,
      empStatus: true,
      departmentName: d,
    },
  };
  const data = await listEmployee(filter);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  dispatch(loadingAction(false));
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_DOCTOR_DATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_DOCTOR_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getSlotData = (labId, date, servicePincode, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  if (labId && date && servicePincode) {
    const data = await getSlots(labId, date, servicePincode, props);
    console.log(data, 'action');
    if (data.length) {
      let temp = [];
      data.map((i) => (temp = temp.concat(i.time)));
      dispatch({
        type: 'GET_LAB_SLOTS',
        payload: temp,
      });
      dispatch(loadingAction(false));
    } else {
      dispatch({
        type: 'GET_LAB_SLOTS',
        payload: [],
      });
      dispatch(loadingAction(false));
    }
  } else {
    dispatch({
      type: 'GET_LAB_SLOTS',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getSlotDurationData = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getSlotDuration();
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_DOCTOR_SLOT_DURATION_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_DOCTOR_SLOT_DURATION_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getScheduleData = (doctorId) => async (dispatch) => {
  dispatch(loadingAction(true));
  if (doctorId) {
    const data = await getSchedule(doctorId);
    if (data && data.length >= 0) {
      dispatch({
        type: 'GET_DOCTOR_SCHEDULE_DATA',
        payload: data,
      });
      dispatch(loadingAction(false));
    } else {
      dispatch({
        type: 'GET_DOCTOR_SCHEDULE_DATA',
        payload: [],
      });
      dispatch(loadingAction(false));
    }
  } else {
    dispatch({
      type: 'GET_DOCTOR_SCHEDULE_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getRoleFunctionsData = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getRolesFunctionalityList();
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_ROLE_FUNCTIONS_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_ROLE_FUNCTIONS_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getRoleListData = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getRolesList();
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_ROLE_LIST_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_ROLE_LIST_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getReferralData = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getReferral();
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_REFERRAL_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_REFERRAL_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getReferralMappingData = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getReferralMappings();
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_REFERRAL_MAPPING_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_REFERRAL_MAPPING_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getReferralRequestData = (d) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getReferralRequest(d);
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_REFERRAL_REQUEST_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_REFERRAL_REQUEST_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getShiftTimeData = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getShifttime();
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_SHIFTTIME_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_SHIFTTIME_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getShiftTimeMapData = () => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getShiftTimeMap();
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_SHIFTTIMEMAP_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_SHIFTTIMEMAP_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};

export const getPharmaCategoryData = (props, path) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getPharmaCategory(path);
  if (data && data.data && data.data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_DRUG_DATA',
      payload: data,
      path: path || 'drug',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_DRUG_DATA',
      payload: [],
      path: path || 'drug',
    });
    dispatch(loadingAction(false));
  }
};
export const getAdminCategoryTypeData = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getAdminCategoryType();
  if (data && data.data && data.data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_CATEGORY_TYPE',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_CATEGORY_TYPE',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getAdminCategoryData = (props, categoryType, status) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getAdminCategory(categoryType, status);
  if (data && data.data && data.data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_CATEGORY',
      payload: data.data,
      name: categoryType,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_CATEGORY',
      payload: [],
      name: categoryType,
    });
    dispatch(loadingAction(false));
  }
};

export const getLabAllData = (props, path, service, data1, type) => async (dispatch) => {
  console.log(service, 'fvsdvsdv');
  let pathName = path;
  console.log(pathName, 'pathName');
  if (service === 'packages') {
    pathName = 'packageList';
  } else if (service === 'laboratory-test-mappings') {
    pathName = 'labMappingList';
  } else {
    pathName = path;
  }
  if (pathName === 'test-samples') {
    pathName = 'testSamples';
  }
  if (service === 'laboratory/all/list') {
    pathName = 'alllab';
  }
  if (service === 'lab-masters/units/all/list') {
    pathName = 'allunits';
  }
  if (service === 'lab-masters/test-samples/all/list') {
    pathName = 'allsamples';
  }
  if (service === 'lab-masters/groups/all/list') {
    pathName = 'allgroups';
  }
  if (service === 'lab-masters/subgroups/all/list') {
    pathName = 'allsubgroups';
  }
  if (service === 'lab-masters/tests/all/list') {
    pathName = 'alltests';
  }
  if (service === 'laboratory/package/all/list') {
    pathName = 'allpack';
  }
  if (service === 'laboratory/all/list' && type === 'external') {
    pathName = 'allExternalLabs';
  }
  console.log(pathName, 'pathName');
  // if (service === 'laboratory-test-mappings') pathName = 'labMappingList';
  dispatch(loadingAction(true));
  const data = await getLabData(path, service, data1, props);
  console.log(data);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data?.data?.items?.length > 0) {
    if (
      (pathName === 'alllab' ||
        pathName === 'allunits' ||
        pathName === 'allsamples' ||
        pathName === 'allgroups' ||
        pathName === 'allsubgroups') &&
      type === 'pagination'
    ) {
      dispatch({
        type: 'GET_LAB_DATA',
        payload: data?.data,
        path: `${pathName}${type}` || 'lab',
      });
    } else {
      dispatch({
        type: 'GET_LAB_DATA',
        payload: data?.data?.items,
        path: pathName || 'lab',
      });
    }
    dispatch(loadingAction(false));
  } else {
    if (
      (pathName === 'alllab' ||
        pathName === 'allunits' ||
        pathName === 'allsamples' ||
        pathName === 'allgroups' ||
        pathName === 'allsubgroups') &&
      type === 'pagination'
    ) {
      dispatch({
        type: 'GET_LAB_DATA',
        payload: [],
        path: `${pathName}${type}` || 'lab',
      });
    } else {
      dispatch({
        type: 'GET_LAB_DATA',
        payload: [],
        path: pathName || 'lab',
      });
    }
    dispatch(loadingAction(false));
  }
};

export const getPackage = (props, path, service, data1, pageNumber, itemPerPage) => async (dispatch) => {
  console.log(service, 'fvsdvsdv');
  let pathName = path;
  console.log(pathName, 'pathName');
  if (service === 'packages/all/list') {
    pathName = 'packageList';
  }
  console.log(pathName, 'pathName');
  // if (service === 'laboratory-test-mappings') pathName = 'labMappingList';
  dispatch(loadingAction(true));
  const data = await getPack(path, service, data1, pageNumber, itemPerPage, props);
  console.log(data);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data?.data?.items?.length > 0) {
    dispatch({
      type: 'GET_PACK_DATA',
      payload: data?.data,
      path: pathName || 'lab',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_PACK_DATA',
      payload: [],
      path: pathName || 'lab',
    });
    dispatch(loadingAction(false));
  }
};
export const getAllLabsAction = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getAllLabData();

  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && Array.isArray(data.data.items) && data.data.items.length > 0) {
    dispatch({
      type: 'GET_LAB_DATA',
      payload: data.data.items,
      path: 'internalAndExternal',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_LAB_DATA',
      payload: [],
      path: 'internalAndExternal',
    });
    dispatch(loadingAction(false));
  }
};
export const getExternalProductAction = (props, labId) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getExternalProductService(labId, props);
  dispatch(loadingAction(false));
  return data;
};
export const getExternalLabsAction = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getExternalLabData(props);

  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && Array.isArray(data.data.items) && data.data.items.length > 0) {
    dispatch({
      type: 'GET_LAB_DATA',
      payload: data.data.items,
      path: 'externalLabs',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_LAB_DATA',
      payload: [],
      path: 'externalLabs',
    });
    dispatch(loadingAction(false));
  }
};
export const getInhouseLabsAction = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getInhouseLabData(props);

  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && Array.isArray(data.data.items) && data.data.items.length > 0) {
    dispatch({
      type: 'GET_LAB_DATA',
      payload: data.data.items,
      path: 'internalLabs',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_LAB_DATA',
      payload: [],
      path: 'internalLabs',
    });
    dispatch(loadingAction(false));
  }
};
export const getLabPageData = (props, dataTest) => async (dispatch) => {
  // console.log(service, 'fvsdvsdv');
  // if (service === 'laboratory-test-mappings') pathName = 'labMappingList';
  dispatch(loadingAction(true));
  const data = await getLabPaginationData(dataTest, props);

  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data.data.items.length > 0) {
    dispatch({
      type: 'GET_LAB_PAGINATION_DATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_LAB_PAGINATION_DATA',
      payload: [],
      path: 'tests',
    });
    dispatch(loadingAction(false));
  }
};
export const getExternalLabAllDataAction = (props, path) => async (dispatch) => {
  // console.log(service, 'fvsdvsdv');
  // if (service === 'laboratory-test-mappings') pathName = 'labMappingList';
  dispatch(loadingAction(true));
  const data = await getExternalLabAllDataService(path, props);

  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data.data.length > 0) {
    dispatch({
      type: 'GET_EXTERNAL_LAB_DATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_EXTERNAL_LAB_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getTestMasterAllData = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getAllTests(props);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_ALLTESTMASTERDATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_ALLTESTMASTERDATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getAllSearchDatabyorderData =
  (booking, patient, pincode, itemPerPage, pageNumber, props) => async (dispatch) => {
    dispatch(loadingAction(true));
    const data = await getAllLabBookingStatusSearch(booking, patient, pincode, itemPerPage, pageNumber, props);
    if (data && data.code === 401) {
      props.history.push({
        pathname: '/login',
        state: {},
      });
      dispatch(loadingAction(false));
    }
    if (data.items && data.items.length > 0) {
      dispatch({
        type: 'GET_ALL_SEARCHDATA',
        payload: data,
      });
      dispatch(loadingAction(false));
    } else {
      dispatch({
        type: 'GET_ALL_SEARCHDATA',
        payload: [],
      });
      dispatch(loadingAction(false));
    }
  };
export const getTestMasterTreeAllData = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getAllTestsTree(props);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_ALLTESTMASTERDATATREE',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_ALLTESTMASTERDATATREE',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getLabAvailableSlotsData = (props, path, req) => async (dispatch) => {
  console.log(path, req);
  dispatch(loadingAction(true));
  const data = await getLabAvailableSlotsByDate(path, req);
  console.log(data);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.code === '200') {
    dispatch({
      type: 'GET_LAB_AVAIL_SLOTS_DATE',
      payload: data?.data?.rules,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_LAB_AVAIL_SLOTS_DATE',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getSubgroupByGroupIdData = (props, path) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getSubgroupByGroupId(path);
  if (data && data.data && data.data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_SUBGROUP_BY_GROUP',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_SUBGROUP_BY_GROUP',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};

export const getSkills = (props, path) => async (dispatch) => {
  const pathName = path;
  dispatch(loadingAction(true));
  const data = await getSkillsData(path);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data.data.length > 0) {
    dispatch({
      type: 'GET_SKILLS_DATA',
      payload: data.data,
      path: pathName || 'levels',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_SKILLS_DATA',
      payload: [],
      path: pathName || 'levels',
    });
    dispatch(loadingAction(false));
  }
};

export const getSkillTypes = (props, path) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getAdminCategory(path);
  if (data && data.data && data.data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_SKILLS_DATA',
      payload: data.data.items,
      path: 'types',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_SKILLS_DATA',
      payload: [],
      path: 'types',
    });
    dispatch(loadingAction(false));
  }
};

export const getAllEmployeeData = (d, f) => async (dispatch, getState) => {
  let roles = [];

  if (Array.isArray(getState().hospital.roleList)) {
    roles = getState().hospital.roleList.map((role) => role.roleName);
  }

  const filter = {
    searchCriteria: {
      role: roles,
      empStatus: true,
      departmentName: d,
      designationName: f,
    },
  };
  dispatch(loadingAction(true));
  const { data } = await listEmployee(filter);

  if (data && data.items && data.items.length > 0) {
    dispatch({
      type: 'GET_ALL_EMPLOYEES',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_ALL_EMPLOYEES',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};

export const getCoupons = (props, path) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getCouponData();
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data.data.length > 0) {
    dispatch({
      type: 'GET_COUPONS_DATA',
      payload: data.data,
      path: 'coupon',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_COUPONS_DATA',
      payload: [],
      path: 'coupon',
    });
    dispatch(loadingAction(false));
  }
};

export const getProductsCoupons = (props, path) => async (dispatch) => {
  let allProducts = [];
  dispatch(loadingAction(true));
  for (const item of path) {
    const data = await getLabData(item);
    if (data && data.code === 401) {
      props.history.push({
        pathname: '/login',
        state: {},
      });
      break;
    }
    if (data && data.data && data.data.length > 0) {
      let conData = [];
      if (item === 'subgroups') {
        conData = data.data.map((item) => {
          return {
            referenceId: item.subGroupId,
            name: item.subGroupName,
            type: 'SUBGROUP',
          };
        });
        dispatch(loadingAction(false));
      }
      if (item === 'packages') {
        conData = data.data.map((item) => {
          return {
            referenceId: item.packageId,
            name: item.packageName,
            type: 'PACKAGE',
          };
        });
        dispatch(loadingAction(false));
      }
      if (item === 'tests') {
        conData = data.data.map((item) => {
          return {
            referenceId: item.testId,
            name: item.testName,
            type: 'TEST',
          };
        });
        dispatch(loadingAction(false));
      }
      allProducts = allProducts.concat(conData);
    }
  }

  allProducts = allProducts.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);

  if (allProducts.length > 0) {
    dispatch({
      type: 'GET_COUPONS_DATA',
      payload: allProducts,
      path: 'products',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_COUPONS_DATA',
      payload: [],
      path: 'products',
    });
    dispatch(loadingAction(false));
  }
};

export const getPatientProfile = (props, parameter) => async (dispatch) => {
  const parameters = {
    itemsPerPage: parameter?.itemsPerPage || 0,
    pageNumber: parameter?.pageNumber || 1,
    searchCriteria: {},
  };

  if (parameter.patientId) {
    parameters.searchCriteria = {
      patientId: parameter.patientId || '',
    };
  }
  dispatch(loadingAction(true));
  const data = await getPatientProfileData(parameters);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_PATIENTPROFILE_DATA',
      payload: data.data,
      path: 'profiles',
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_PATIENTPROFILE_DATA',
      payload: [],
      path: 'profiles',
    });
    dispatch(loadingAction(false));
  }
};
export const getLabBookingDetailsData = (props, path) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getLabBookingDetails(path);
  console.log(data);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.code === '200' && data.data && data.data.length > 0) {
    dispatch({
      type: 'GET_LAB_DETAILS_BY_BOOKINGID',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_LAB_DETAILS_BY_BOOKINGID',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getLabExecutiveData = (roles, labId, props) => async (dispatch) => {
  const filter = {
    searchCriteria: {
      role: roles,
      empStatus: true,
      labId: labId,
    },
  };
  dispatch(loadingAction(true));
  const data = await listEmployee(filter);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data.data.items && data.data.items.length > 0) {
    dispatch({
      type: 'GET_LAB_EXECUTIVE_DATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_LAB_EXECUTIVE_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};

// export const getLabExecutiveDataByPincode = (path, labId, pincode, props) => async (dispatch) => {
//   const data = await getExecutiveByPincode(path, labId, pincode);
//   console.log(data);
//   if (data && data.code === 401) {
//     props.history.push({
//       pathname: '/login',
//       state: {},
//     });
//   }
//   if (data && data.data && data.data.items && data.data.items.length > 0) {
//     dispatch({
//       type: 'GET_ASSIGN_EXECUTIVE_DATA',
//       payload: data.data,
//     });
//   } else {
//     dispatch({
//       type: 'GET_ASSIGN_EXECUTIVE_DATA',
//       payload: [],
//     });
//   }
// };
export const getLabExecutiveDataByPincode = (path, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getExecutiveByPincode(path, props);
  console.log(data);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.length > 0) {
    dispatch({
      type: 'GET_ASSIGN_EXECUTIVE_DATA',
      payload: data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_ASSIGN_EXECUTIVE_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getgrouplist = (path, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getgroup(path, props);

  console.log(data);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data?.items.length > 0) {
    dispatch({
      type: 'GET_GROUP_DATA',
      payload: data?.items,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_GROUP_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getSubgrouplist = (path, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getSubgroup(path, props);

  console.log(data);
  console.log(data.items);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data?.items.length > 0) {
    dispatch({
      type: 'GET_SUPGROUP_DATA',
      payload: data?.items,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_SUPGROUP_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getPincodeExective = (path, data1, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getExecutiveByservice(path, data1, props);
  console.log(data);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data?.items?.length > 0) {
    dispatch({
      type: 'GET_PINCODE_EXECUTIVE_DATA',
      payload: data.items,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_PINCODE_EXECUTIVE_DATA',
      payload: [],
    });
    dispatch(loadingAction(false));
  }
};
export const getAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const dateObjToString = (dateString) => {
  return `${new Date(dateString).getFullYear()}-${`0${new Date(dateString).getMonth() + 1}`.slice(-2)}-${`0${new Date(
    dateString,
  ).getDate()}`.slice(-2)}`;
};
export const getdashboarddata = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getdashborad(data1);
  if (data) {
    dispatch({
      type: 'GET_DASH_DATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  }
};
export const getdashboarddata1 = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getdashborad(data1);
  console.log(data.data);
  if (data) {
    dispatch({
      type: 'GET_DASH1_DATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  }
};

export const getdashboardcitydata = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getdashborad(data1);
  console.log(data.data);
  if (data) {
    dispatch({
      type: 'GET_DASHCITY_DATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  }
};
export const adgetdashboarddata = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getdashborad(data1);
  if (data) {
    dispatch({
      type: 'GET_ADDASH_DATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  }
};
export const adgetdashboarddata1 = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getdashborad(data1);
  console.log(data.data);
  if (data) {
    dispatch({
      type: 'GET_ADDASH1_DATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  }
};

export const adgetdashboardcitydata = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getdashborad(data1);
  console.log(data.data);
  if (data) {
    dispatch({
      type: 'GET_ADDASHCITY_DATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  }
};
export const getdashboardcitypin = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getdashborad(data1);
  console.log(data.data);
  if (data) {
    dispatch({
      type: 'GET_DASHCITYPIN_DATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  }
};
export const postdashboarddata = (day) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await postdashboard(day);
  console.log(data);
  if (data) {
    dispatch({
      type: 'GET_POSTDASH_DATA',
      payload: data.data.items,
    });
    dispatch(loadingAction(false));
  }
};
export const getCategorydata = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getCategoryList(data1);
  console.log(data);
  if (data) {
    dispatch({
      type: 'GET_CATEGORY1',
      payload: data.items,
    });
    dispatch(loadingAction(false));
  }
};
export const getCategorygenderdata = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getCategoryList(data1);
  console.log(data);
  if (data) {
    dispatch({
      type: 'GET_CATEGORYGEN',
      payload: data.items,
    });
    dispatch(loadingAction(false));
  }
};
export const admindata = (path, data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await admindash(path, data1);
  console.log(data);
  if (data) {
    dispatch({
      type: 'GET_ADMINDATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  }
};
export const countofdde = (path, data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await countofdoc(path, data1);
  console.log(data);
  if (data) {
    dispatch({
      type: 'GET_COUNTDATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  }
};
export const countofappointment = (path, data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await countappoint(path, data1);
  console.log(data);
  if (data) {
    dispatch({
      type: 'GET_COUNTAPPOINTDATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  }
};
export const countoflabstatus = (path, data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await countlab(path, data1);
  console.log(data);
  if (data) {
    dispatch({
      type: 'GET_COUNTLABDATA',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  }
};
export const numberofpatient = (data1) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await numberofpa(data1);
  console.log(data);
  if (data) {
    dispatch({
      type: 'GET_PATIENTCOUNT',
      payload: data.data,
    });
    dispatch(loadingAction(false));
  }
};
export const getTestSampleAction = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getTestSampleService(props);
  console.log(data);
  if (data) {
    dispatch({
      type: 'GET_SAMPLE_TESTS',
      payload: data,
    });
    dispatch(loadingAction(false));
  }
};
export const createExternalLabMappingAction = (props, result, update) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await createExternalLabMappingService(result, update, props);
  if (data && data.data && data.data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  console.log(data, 'data external lab');
  dispatch(loadingAction(false));
};
export const getExternalLabByIDAction = (props, id) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getExternalLabByIDService(id, props);
  if (data && data.data && data.data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
    dispatch(loadingAction(false));
  }
  console.log(data, 'data external lab');
  if (Array.isArray(data.data) && data.data.length > 0) {
    dispatch(loadingAction(false));
    return data.data[0];
  } else {
    dispatch(loadingAction(false));
    return '';
  }
};
export const getTemplateStatusTypeAction = (props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getTemplateStatusTypeService(props);
  console.log(data);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data?.data?.length > 0) {
    dispatch({
      type: 'GET_TEMPLEATE_STATUS',
      payload: data?.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_TEMPLEATE_STATUS',
      payload: [],
    });
  }
  dispatch(loadingAction(false));
};
export const getServicePincodeByLabData = (path, data1, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getServicePincodeByLab(path, data1, props);
  console.log(data);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data.data && data?.data?.items?.length > 0) {
    console.log(data);
    dispatch({
      type: 'GET_LAB_PINCODE_PAGENATION',
      payload: data?.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_LAB_PINCODE_PAGENATION',
      payload: [],
    });
  }
  dispatch(loadingAction(false));
};
export const getUploadTemplateStatusAction = (searchCriteria, props) => async (dispatch) => {
  dispatch(loadingAction(true));
  const data = await getUploadTemplateStatusService(searchCriteria, props);
  console.log(data);
  if (data && data.code === 401) {
    props.history.push({
      pathname: '/login',
      state: {},
    });
  }
  if (data && data.data && data?.data?.items?.length > 0) {
    dispatch({
      type: 'GET_UPLOADEDTEMPLEATE_STATUS',
      payload: data?.data,
    });
    dispatch(loadingAction(false));
  } else {
    dispatch({
      type: 'GET_UPLOADEDTEMPLEATE_STATUS',
      payload: [],
    });
  }
  dispatch(loadingAction(false));
};
