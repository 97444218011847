/* eslint-disable array-callback-return */
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { getToken, removeToken } from '../utils/utils';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { loadingAction } from '../redux/actions/actions';
// import { Redirect } from 'react-router-dom';
// const baseLabURL = process.env.REACT_APP_HRMS_SERVICE_URL;
export const baseLabURL = process.env.REACT_APP_BASE_URL + '/diahome/v2/api/laboratory-service';
// const baseLabURL = process.env.REACT_APP_PATIENT_SERVICE_URL;
// const baseLabURL = process.env.REACT_APP_AUTH_SERVICE_URL;
// const baseLabURL = process.env.REACT_APP_ADMIN_SERVICE_URL;
// const baseLabURL = process.env.REACT_APP_FILE_SERVICE_URL;
const headers = () => {
  return {
    applicationLabel: 'Lab-Portal',
    correlationId: uuidv4(),
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  };
};
// const headersHRMS = () => {
//   return {
//     applicationLabel: 'HRMS-Portal',
//     correlationId: uuidv4(),
//     'Content-Type': 'application/json',
//     Authorization: `Bearer ${getToken()}`,
//   };
// };
const triggerToastSuccess = (message) => {
  toast.success(message, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};
const triggerToastError = (message) => {
  toast.error(message, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};
export const logoutService = (props) => {
  props.history.push({
    pathname: '/login',
    state: {},
  });
  return removeToken();
};
export const errormsg = (error, props) => {
  if (error && error.response?.status === 404) {
    triggerToastError(`Not found`);
  } else if (error && error.response?.status === 401) {
    console.log(error.response);
    const err = error.response.data.errors;
    err.map((value) => {
      triggerToastError(`${value.message}`);
    });
    if (props) logoutService(props);
    // <Redirect to="/login" />;
  } else if (error && error.response?.status >= 500) {
    triggerToastError(`Server Error`);
  } else if (error && error.response && error.response.data.errors?.length > 0) {
    const err = error.response.data.errors;
    err.map((value) => {
      triggerToastError(`${value.message}`);
    });
  } else {
    triggerToastError(`${error && error.response && error.response.message}`);
  }
};
export const profileDetails = async (d, dispatch, props) => {
  const data = {
    emailAddress: d,
  };
  const config = {
    method: 'post',
    url: `${baseLabURL}/employee/profile/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      if (response && response.data && response.data.status === 200) {
        localStorage.setItem('empId', response.data.data[0].empId);
        dispatch({
          type: 'USERPROFILE',
          payload: response.data.data[0].empId,
        });
        return response.data;
      }
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getIfcecode = async (id, props) => {
  const config = {
    method: 'GET',
    url: `https://ifsc.razorpay.com/${id}`,
  };
  return await axios(config)
    .then(function (response) {
      console.log(response.data);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const login = async (d, dispatch, props) => {
  const data = JSON.stringify(d);
  const config = {
    method: 'post',
    url: `${baseLabURL}/employee/login/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      if (response && response.data && response.data.status === 200) {
        // const msg = response.data.message;
        // const message =
        //   msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
        // triggerToastSuccess(message);
        // triggerToast(response.data.message);
        localStorage.setItem('accessToken', response.data.data.accessToken);
        localStorage.setItem('email', response.data.data.email);
        localStorage.setItem('roles', response.data.data.roles);
        localStorage.setItem('username', response.data.data.username);
        localStorage.setItem('labcode', response.data.data.basicProfile.labCode);
        localStorage.setItem('labname', response.data.data.basicProfile.labName);
        localStorage.setItem('functions', response.data.data.functions);
        localStorage.setItem('loginDuration', response.data.data.loginDuration);
        profileDetails(response.data.data.email, dispatch);
        return response.data;
      }
    })
    .catch(function (error) {
      errormsg(error, props);
      dispatch(loadingAction(false));
    });
};
export const logout = async (data, props) => {
  console.log(props, 'props');
  const config = {
    method: 'post',
    url: `${baseLabURL}/employee/logout/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      if (response && response.data && response.data.status === 200) {
        triggerToastSuccess(response.data.data.message);
        // localStorage.setItem('accessToken', response.data.data.accessToken);
        // localStorage.setItem('firstlog', response.data.data.firstLogin);
        // localStorage.setItem('email', response.data.data.email);
        // localStorage.setItem('roles', response.data.data.roles);
        // localStorage.setItem('username', response.data.data.username);
        // localStorage.setItem('functions', response.data.data.functions);
        // profileDetails(response.data.data.email);
        return response.data;
      }
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const forgetpass = (data) => {
  const config = {
    method: 'put',
    url: `${baseLabURL}/employee/forgot-password/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      triggerToastSuccess(response.data.data);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const Changepass = (data, changePasswordSuccess) => {
  const config = {
    method: 'put',
    url: `${baseLabURL}/employee/change-password/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      if (changePasswordSuccess) changePasswordSuccess();
      triggerToastSuccess(response.data.data);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const verifyOtp = (data) => {
  const config = {
    method: 'put',
    url: `${baseLabURL}/employee/verify-otp/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};

export const getCategoryList = (categoryType, props) => {
  const data = JSON.stringify({
    searchCriteria: {
      categoryType: categoryType,
      isActive: true,
    },
  });

  const config = {
    method: 'post',
    url: `${baseLabURL}/masters/categories/findall`,
    headers: {
      applicationLabel: 'Lab-Portal',
      correlationId: uuidv4(),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const getHospital = () => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/hospital/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.length > 0
      ) {
        const code = error.response.data.errors[0];
        return code && code[0];
      } else {
        return { code: 500 };
      }
    });
};

export const getBranch = () => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/branch/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getStateMaster = (props) => {
  const data = JSON.stringify({
    searchCriteria: {},
  });

  const config = {
    method: 'post',
    url: `${baseLabURL}/masters/states/findall`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

// export const getCityMaster = (stateCodeName) => {
//   const data = JSON.stringify({
//     searchCriteria: { stateCodeName: stateCodeName },
//   });

//   const config = {
//     method: 'post',
//     url: `${baseLabURL}/masters/cities/findall`,
//     headers: headers(),
//     data: data,
//   };

//   return axios(config)
//     .then(function (response) {
//       return response.data.data.items;
//     })
//     .catch(function (error) {
//       console.log(error);
//     });
// };
export const getCityMaster = (stateCodeName, props) => {
  // const data = JSON.stringify({
  //   searchCriteria: { stateCodeName: stateCodeName },
  // });

  const config = {
    method: 'GET',
    url: `${baseLabURL}/masters/cities/find-by-state/${stateCodeName}`,
    headers: headers(),
    // data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const getState = (props) => {
  // const data = JSON.stringify({
  //   searchCriteria: {},
  // });

  const config = {
    method: 'GET',
    url: `${baseLabURL}/laboratory/services/masters/pin_codes/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const getCity = (stateCodeName, props) => {
  // const data = JSON.stringify({
  //   searchCriteria: { stateCodeName: stateCodeName },
  // });

  const config = {
    method: 'GET',
    url: `${baseLabURL}/laboratory/services/masters/pin_codes/?state=${stateCodeName}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getPincode = (stateCodeName, cityCodeName, props) => {
  // const data = JSON.stringify({
  //   searchCriteria: { stateCodeName: stateCodeName },
  // });

  const config = {
    method: 'GET',
    url: `${baseLabURL}/laboratory/services/masters/pin_codes/?state=${stateCodeName}&city=${cityCodeName}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      errormsg(error, props);
    });
};

export const createEmployee = (_d) => {
  const data = JSON.stringify(_d);
  const config = {
    method: 'post',
    url: `${baseLabURL}/employee/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      console.log(error.response);
      triggerToastError(`${error.message}`);
      return error.response;
    });
};
export const createReferralDoctor = (_d) => {
  const data = JSON.stringify(_d);
  const config = {
    method: 'post',
    url: `${baseLabURL}/referred/`,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      if (error.response && error.response.data && error.response.data.errors)
        triggerToastError(`${error.response.data.errors[0]}`);
      return error.response;
    });
};
export const getReferralRequest = (d) => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/referred/${d}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error.response);
    });
};
export const updateEmployee = (_d) => {
  const data = JSON.stringify(_d);
  const config = {
    method: 'put',
    url: `${baseLabURL}/employee/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
    })
    .catch(function (error) {
      triggerToastError(`${error.message}`);
      console.log(error);
    });
};
export const generateUser = (_d) => {
  const data = JSON.stringify(_d);
  const config = {
    method: 'post',
    url: `${baseLabURL}/employee/generate-user/`,
    headers: headers(),
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(response);
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
    })
    .catch(function (error) {
      triggerToastError(`${error.message}`);
      console.log(error);
    });
};
export const roleUpdateEmployee = async (_d, generateUserInd) => {
  const data = JSON.stringify(_d);
  const config = {
    method: 'put',
    url: `${baseLabURL}/employee/`,
    headers: headers(),
    data: data,
  };

  await axios(config)
    .then(function (response) {
      if (generateUserInd) generateUser(response.data.data);
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
    })
    .catch(function (error) {
      triggerToastError(`${error.message}`);
      console.log(error);
    });
};

export const listEmployee = (d) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/listEmployee/`,
    headers: headers(),
    data: d,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSchedule = (doctorId) => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/roaster/schedule-masters?doctorId=${doctorId}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const getSlots = (labId, date, servicePincode, props) => {
  let service = '';
  if (servicePincode) {
    service = `?serviceId=${servicePincode}`;
  } else {
    service = '';
  }
  console.log(date, 'date');
  const formetDate = moment(date).format('YYYY-MM-DD');
  const config = {
    method: 'get',
    url: `${baseLabURL}/laboratory/slots/get-by-date/${labId}/${formetDate}${service}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      errormsg(error, props);
    });
};
export const getSlotDuration = () => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/roaster/slot/duration/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const getTestSampleService = (props) => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/lab-masters/test-samples/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error, props);
    });
};
export const deleteSlot = async (data, props) => {
  const config = {
    method: 'POST',
    url: `${baseLabURL}/laboratory/slots/change-slots-status/DELETED/`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      const msg = response.data.data.message;
      triggerToastSuccess(msg);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // if (error && error.response && error.response.data.errors[0].message) {
      //   triggerToastError(`${error && error.response && error.response.data.errors[0].message}`);
      // } else {
      //   triggerToastError(`${error && error.response && error.response.message}`);
      // }
      // return error.response;
    });
};
export const openSlot = async (data, props) => {
  const config = {
    method: 'POST',
    url: `${baseLabURL}/laboratory/slots/change-slots-status/OPEN/`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      const msg = response.data.data.message;
      triggerToastSuccess(msg);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // if (error && error.response && error.response.data.errors[0].message) {
      //   triggerToastError(`${error && error.response && error.response.data.errors[0].message}`);
      // } else {
      //   triggerToastError(`${error && error.response && error.response.message}`);
      // }
      // return error.response;
    });
};
export const getEmployee = (status = 'true') => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/employee/?empStatus=${status}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const getRoles = () => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/masters/roles`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const getRolesFunctionalityList = () => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/roles/functions`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const getRolesList = () => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/roles/listRoles`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const createRole = (_d, method) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseLabURL}/roles`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.message}`);
      return error.response;
    });
};
export const getReferral = () => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/referrals/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const createReferral = (_d, method) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseLabURL}/referrals/`,
    headers: headers(),
    data: data,
  };

  axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      triggerToastError(`${error.message}`);
      console.log(error);
    });
};
export const createReferralMapping = (_d, method) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseLabURL}/referrals/mappings`,
    headers: headers(),
    data: data,
  };

  axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      triggerToastError(`${error.message}`);
      console.log(error);
    });
};
export const getReferralMappings = () => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/referrals/mappings`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const createShiftTime = (_d, method) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseLabURL}/roaster/shift`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.message}`);
      return error.response;
    });
};
export const getShifttime = () => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/roaster/shift`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const getShiftTimeMap = () => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/roaster/employeeshiftmappings`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error.response);
    });
};
export const createHospital = async (_d, method) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseLabURL}/hospital/`,
    headers: headers(),
    data: data,
  };

  await axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      triggerToastError(`${error.message}`);
      console.log(error);
    });
};

export const createBranch = (_d, method) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseLabURL}/branch/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.message}`);
      return error.response;
    });
};

export const createScheduleMaster = async (_d) => {
  const data = JSON.stringify(_d);
  const config = {
    method: 'post',
    url: `${baseLabURL}/roaster/schedule-masters`,
    headers: headers(),
    data: data,
  };

  await axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      triggerToastError(`${error.message}`);
      console.log(error);
    });
};
export const updateScheduleMaster = (_d) => {
  const data = JSON.stringify(_d);
  const config = {
    method: 'put',
    url: `${baseLabURL}/roaster/schedule-masters`,
    headers: headers(),
    data: data,
  };

  axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      triggerToastError(`${error.message}`);
      console.log(error);
    });
};

export const updateActiveInactiveStatus = async (id, path, method, props) => {
  // const data = JSON.stringify(_d);
  // const result = {
  //   id: _d.id,
  //   referralCodeName: _d?.referralCodeName?.codeName,
  //   referenceTo: _d?.referenceTo?.empId,
  // };
  if (id) id = id + '/';
  const config = {
    method: method,
    url: `${baseLabURL}${path}${id}`,
    headers: headers(),
  };

  return await axios(config)
    .then(function (response) {
      const msg = response.data.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const updateActiveInactiveStatusPatientService = async (_d, path, method) => {
  const data = JSON.stringify(_d);
  const config = {
    method: method,
    url: `${baseLabURL}${path}`,
    headers: headers(),
    data: data,
  };

  return await axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.message}`);
      return error.response;
    });
};

export const getPharmaCategory = (path) => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/drug/${path}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const createPharmaCategory = (path, method, data) => {
  const config = {
    method: method,
    url: `${baseLabURL}/drug/${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};

export const createAdminCategoryType = (method, data) => {
  const config = {
    method: method,
    url: `${baseLabURL}/masters/categories/types`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const createAdminCategory = (method, data) => {
  const config = {
    method: method,
    url: `${baseLabURL}/masters/categories`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const updateOrderAdminCategory = (method, data) => {
  const config = {
    method: method,
    url: `${baseLabURL}/masters/categories/list-update/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const getAdminCategoryType = () => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/masters/categories/types/findall`,
    headers: headers(),
    data: {
      itemsPerPage: 0,
      pageNumber: 0,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error && error.response && error.response.data.message}`);
      return error.response;
    });
};
export const getAdminCategory = (categoryType, isActive, props) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/masters/categories/findall`,
    headers: headers(),
    data: {
      itemsPerPage: 0,
      pageNumber: 0,
      searchCriteria: {
        categoryType: categoryType,
        isActive: isActive,
      },
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      // triggerToastError(`${error.response.data.message}`);\
      errormsg(error, props);
      return error.response;
    });
};

export const getLabData = (path, service, data, props) => {
  let pathUrl;
  if (path) {
    pathUrl = path + '/';
  } else {
    pathUrl = path;
  }
  const config = {
    method: 'post',
    url: `${baseLabURL}/${service}/${pathUrl}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // return error.response;
    });
};
export const getPack = (path, service, data, pageNumber, itemPerPage, props) => {
  let pathUrl;
  if (path) {
    pathUrl = path + '/';
  } else {
    pathUrl = path;
  }
  const config = {
    method: 'post',
    url: `${baseLabURL}/${service}/${pathUrl}`,
    headers: headers(),
    data: { searchCriteria: data, itemsPerPage: itemPerPage, pageNumber: pageNumber },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // return error.response;
    });
};
export const getPackbyId = (id, props) => {
  const config = {
    method: 'GET',
    url: `${baseLabURL}/packages/${id}/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // return error.response;
    });
};
export const getAllLabData = () => {
  const data = {
    searchCriteria: {},
  };
  const config = {
    method: 'POST',
    url: `${baseLabURL}/laboratory/all/list/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
      // return error.response;
    });
};
export const getExternalProductService = (labId, props) => {
  const config = {
    method: 'GET',
    url: `${baseLabURL}/laboratory/externalAPIMapping/list/products/?labId=${labId}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // return error.response;
    });
};
export const getExternalLabData = (props) => {
  const data = {
    searchCriteria: {
      labType__in: ['External'],
    },
  };
  const config = {
    method: 'POST',
    url: `${baseLabURL}/laboratory/all/list/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // return error.response;
    });
};
export const getInhouseLabData = (props) => {
  const data = {
    searchCriteria: {
      labType__in: ['Internal'],
    },
  };
  const config = {
    method: 'POST',
    url: `${baseLabURL}/laboratory/all/list/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // return error.response;
    });
};
export const getLabPaginationData = (dataTest, props) => {
  // const data = {
  //   searchCriteria: search,
  //   itemsPerPage: itemPerPage,
  //   pageNumber: pageNumber,
  //   // sortInstruction: {
  //   //   attributeName: 'priority',
  //   //   sortDirection: 1,
  //   // },
  // };
  const config = {
    method: 'POST',
    url: `${baseLabURL}/lab-masters/findall/`,
    headers: headers(),
    data: dataTest,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // return error.response;
    });
};
export const getExternalLabAllDataService = (path, props) => {
  const config = {
    method: 'GET',
    url: `${baseLabURL}/${path}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // return error.response;
    });
};
export const getLabDataTree = (path, service) => {
  let pathUrl;
  if (path) {
    pathUrl = path + '/';
  } else {
    pathUrl = path;
  }
  const config = {
    method: 'POST',
    url: `${baseLabURL}/${service}/${pathUrl}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
      // return error.response;
    });
};
export const getuserlab = (id, props) => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/laboratory/${id}/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // return error.response;
    });
};
export const getserviceHC = (data, id, props) => {
  const config = {
    method: 'put',
    url: `${baseLabURL}/home-collection/executive/${id}/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      triggerToastSuccess('Assigned Successfully');
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // return error.response;
    });
};

export const getSubgroupByGroupId = (path, props) => {
  const config = {
    method: 'get',
    url: `${baseLabURL}${path}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const createLabData = (path, method, data, id, service, props) => {
  console.log(path, method, data);
  let pathUrl;
  if (path) {
    pathUrl = path + '/';
  } else {
    pathUrl = path;
  }
  if (id) {
    id = id + '/';
  } else {
    id = '';
  }
  const config = {
    method: method,
    url: `${baseLabURL}/${service}/${pathUrl}${id}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.data.message;
      triggerToastSuccess(msg);
      // triggerToast(response.data.message);
      return response.data.status;
    })
    .catch(function (error) {
      console.log(error.response, 'error');
      errormsg(error, props);
      return error.response.status;
    });
};

export const getSkillsData = (path) => {
  let pathUrl;
  if (path) {
    pathUrl = path + '/';
  } else {
    pathUrl = path;
  }
  const config = {
    method: 'get',
    url: `${baseLabURL}/skills/${pathUrl}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};

export const modifySkillsData = (path, method, data) => {
  let pathUrl;
  if (path) {
    pathUrl = path + '/';
  } else {
    pathUrl = path;
  }
  const config = {
    method: method,
    url: `${baseLabURL}/skills/${pathUrl}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response?.data?.message}`);
      return error.response;
    });
};

export const getCouponData = () => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/coupons`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response?.data?.message}`);
      return error.response;
    });
};

export const modifyCouponData = (path, method, data) => {
  const config = {
    method: method,
    url: `${baseLabURL}/coupons/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response?.data?.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      // triggerToast(`${response?.data?.message}`);
      return response?.data?.data;
    })
    .catch(function (error) {
      triggerToastError(`${error?.response?.data?.message}`);
      return error?.response;
    });
};

export const getServicePincodeByLab = (path, data, props) => {
  const config = {
    method: 'POST',
    url: `${baseLabURL}${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const getLabBookings = (status, labId, pageNumber, itemsPerPage) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/laboratory/bookings/findall`,
    headers: headers(),
    data: {
      pageNumber: pageNumber,
      itemsPerPage: itemsPerPage,
      searchCriteria: {
        status: status,
        labId: labId,
      },
      sortInstruction: {
        attributeName: 'date',
        sortDirection: -1,
      },
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const createServicePincodeByLab = (path, method, data, props) => {
  const config = {
    method: method,
    url: `${baseLabURL}${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const assignAgent = (path, method, data, props) => {
  const config = {
    method: method,
    url: `${baseLabURL}${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const updateOrderLabPincodes = (method, data, props) => {
  console.log(data);
  const config = {
    method: method,
    url: `${baseLabURL}/laboratory/services/available/change-order/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

// export const updateLabBookingStatus = (method, bookingId, status) => {
//   if (bookingId && bookingId.length > 0 && status) {
//     const data = bookingId.map((b) => {
//       return {
//         status: status,
//         description: '',
//         message: '',
//         bookingId: b,
//         updatedBy: getUsername(),
//       };
//     });
//     console.log(data);
//     const config = {
//       method: method,
//       url: `${baseLabURL}/laboratory/bookings/set-status/`,
//       headers: headers(),
//       data: data,
//     };

//     return axios(config)
//       .then(function (response) {
//         const msg = response.data.message;
//         const message =
//           msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
//         triggerToastSuccess(message);
//         // triggerToast(response.data.message);
//         return response.data;
//       })
//       .catch(function (error) {
//         console.log(error);
//         triggerToastError(`${error.response.data.message}`);
//         return error.response;
//       });
//   } else {
//     triggerToastError('Please select atleast one Booking and status');
//   }
// };
export const updateLabBookingStatus = (method, bookingId, status, cancelReason, props) => {
  // if (bookingId && bookingId.length > 0 && status) {
  //   const data = bookingId.map((b) => {
  //     return {
  //       status: status,
  //       description: '',
  //       message: '',
  //       bookingId: b,
  //       updatedBy: getUsername(),
  //     };
  //   });
  const data = {
    status: status,
    remarks: cancelReason,
  };
  //   console.log(data);
  const config = {
    method: method,
    url: `${baseLabURL}/laboratory/bookings/update/${bookingId}/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response.data.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
  // } else {
  //   triggerToastError('Please select atleast one Booking and status');
  // }
};
export const getPatientProfileData = (data) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/patients/profiles/`,
    headers: headers(),
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const getPatientProfileData1 = (data) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/patients/profiles/all/list/`,
    headers: headers(),
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const uploadHealthRecords = (data, id) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/caretakers/casesheet/${id}/healthrecords/`,
    headers: headers(),
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      triggerToastError(`${error.response.data.message}`);
      return error.response;
    });
};
export const modifyPatientProfile = (method, data) => {
  const config = {
    method: method,
    url: `${baseLabURL}/profiles/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response?.data?.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      // triggerToast(`${response?.data?.message}`);
      return response?.data?.data;
    })
    .catch(function (error) {
      triggerToastError(`${error?.response?.data?.message}`);
      return error?.response;
    });
};

export const createSlotDuration = (path, method, data) => {
  const config = {
    method: method,
    url: `${baseLabURL}${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      const msg = response?.data?.message;
      const message =
        msg === 'OK' ? 'Successfully Updated' : response.data.message === 'Created' ? 'Successfully Created' : msg;
      triggerToastSuccess(message);
      // triggerToast(response.data.message);
      // triggerToast(`${response?.data?.message}`);
      return response?.data?.data;
    })
    .catch(function (error) {
      triggerToastError(`${error?.response?.data?.message}`);
      return error?.response;
    });
};
export const getselectcity = async (city, props) => {
  const config = {
    method: 'GET',
    url: `${baseLabURL}/masters/places/search/map?query=${city}`,
    headers: headers(),
  };
  return await axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const fileAttachmentUploadURLGen = async (fileDetails, props, picUploadBody) => {
  try {
    return await axios({
      method: 'POST',
      url: `${baseLabURL}/file-storage/get-signed-url/`,
      headers: {
        applicationLabel: 'Lab-Portal',
        correlationId: uuidv4(),
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: picUploadBody,
    })
      .then(async (res) => {
        if (res.status >= 200) {
          if (res.data.status >= 200 && res.data.status < 400) {
            const url = res.data.data.signedUrl;
            const fileName = res.data.data.fileName;
            const fileUrl = res.data.data.fileUrl;
            const response = await fileAttachmentUpload(fileDetails, props, url, fileName, baseLabURL);
            console.log(response, 'response');
            if (response.status === 200) return fileUrl;
            triggerToastError(`File Upload failed. Try after Sometimes.`);
          }
        }
      })
      .catch((err) => {
        errormsg(err);
      });
  } catch (error) {
    console.log(error, 'error');
  }
};
export const fileAttachmentUploadURLGen1 = async (fileDetails, props, picUploadBody) => {
  try {
    return await axios({
      method: 'POST',
      url: `${baseLabURL}/file-storage/get-signed-url/`,
      headers: {
        applicationLabel: 'Lab-Portal',
        correlationId: uuidv4(),
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: picUploadBody,
    })
      .then(async (res) => {
        if (res.status >= 200) {
          if (res.data.status >= 200 && res.data.status < 400) {
            const url = res.data.data.signedUrl;
            const fileName = res.data.data.filename;
            const fileUrl = res.data.data.fileUrl;
            const response = await fileAttachmentUpload(fileDetails, props, url, fileName, fileUrl);
            console.log(response, 'response');
            console.log(fileName, 'fileName');
            if (response.status === 200) return fileName;
            triggerToastError(`File Upload failed. Try after Sometimes.`);
          }
        }
      })
      .catch((err) => {
        errormsg(err, props);
      });
  } catch (error) {
    console.log(error, 'error');
  }
};
export const documentAttachmentUploadURLGen = async (fileDetails, props, picUploadBody) => {
  try {
    return await axios({
      method: 'POST',
      url: `${baseLabURL}/file-storage/get-signed-url/`,
      headers: {
        applicationLabel: 'Lab-Portal',
        correlationId: uuidv4(),
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: picUploadBody,
    })
      .then(async (res) => {
        console.log(res);
        if (res.status >= 200) {
          if (res.data.status >= 200 && res.data.status < 400) {
            const url = res.data.data.signedUrl;
            const fileName = res.data.data.filename;
            const fileUrl = res.data.data.fileUrl;
            const response = await fileAttachmentUpload(fileDetails, props, url, fileName, fileUrl);
            console.log(response, 'response');

            if (response.status === 200) return fileName;
            triggerToastError(`File Upload failed. Try after Sometimes.`);
          }
        }
      })
      .catch((err) => {
        triggerToastError(err.response);
      });
  } catch (error) {
    console.log(error, 'error');
  }
};

export const fileAttachmentUpload = async (file, props, url, fileName, baseLabURL) => {
  console.log(file.type, 'fileAttachmentUpload');
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      axios
        .request({
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': file?.type, // This is set to application/flac
            // 'Content-Disposition': `attachment; filename=${file.type}`,
          },
          method: 'PUT',
          data: reader.result,
          url: url,
          timeout: 0,
          onUploadProgress: (progressEvent) => {
            const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100 - 10;
            console.log(uploadProgress, 'uploadProgress');
          },
        })
        .then((response) => {
          resolve(response);
          console.log(response, 'fileUpload');
        })
        .catch((err) => reject(err));
    };
    reader.onerror = (err) => {
      err = { error: { message: 'file read error' } };
      reject(err);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const getProfilePic = async (fileName) => {
  console.log(fileName, 'getProfilePic');
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `http://34.120.164.65/diahome/v1/api/admin-service/file-storage/generate-url-to-get?file_name=${encodeURIComponent(
        fileName,
      )}`,
      headers: {
        applicationLabel: 'Admin-Portal',
        correlationId: uuidv4(),
        // 'Content-Type': 'application/json',
        // Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((result) => {
        return resolve(result.data?.data?.signedUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};
export const downloadDocument = async (file, props) => {
  console.log(file, 'getProfilePic');
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${baseLabURL}/file-storage/get-signed-url/`,

      headers: {
        applicationLabel: 'Lab-Portal',
        correlationId: uuidv4(),
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: file,
    })
      .then((result) => {
        // document.open(result.data?.data?.signedUrl);
        console.log(result);
        return resolve(result.data?.data?.signedUrl);
      })
      .catch((err) => {
        console.log(err);
        errormsg(err, props);
      });
  });
};

// export const getExecutiveByPincode = (path, labId, pincode) => {
//   const config = {
//     method: 'post',
//     url: `${baseLabURL}${path}`,
//     headers: headers(),
//     data: {
//       pageNumber: 0,
//       searchCriteria: {
//         labId: labId,
//         pincode: pincode,
//       },
//     },
//   };

//   return axios(config)
//     .then(function (response) {
//       return response.data;
//     })
//     .catch(function (error) {
//       triggerToastError(`${error.response.data.message}`);
//       return error.response;
//     });
// };
export const getExecutiveByPincode = (path, props) => {
  const config = {
    method: 'GET',
    url: `${baseLabURL}${path}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getExecutiveByservice = (path, data, props) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}${path}`,
    headers: headers(),
    data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getgroup = (path, props) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}${path}`,
    headers: headers(),
    data: {
      sortInstruction: [
        {
          attributeName: 'priority',
          sortDirection: 1,
        },
      ],
    },
  };
  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getSubgroup = (id, props) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/lab-masters/subgroups/all/list/`,
    headers: headers(),
    data: {
      searchCriteria: {
        group: id,
      },
      sortInstruction: [
        {
          attributeName: 'priority',
          sortDirection: 1,
        },
      ],
    },
  };
  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getTest = (id, props) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/lab-masters/findall/`,
    headers: headers(),
    data: {
      searchCriteria: {
        subGroup: id,
      },
      sortInstruction: [
        {
          attributeName: 'priority',
          sortDirection: 1,
        },
      ],
    },
  };
  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getSamplename = (bookid, props) => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/laboratory/test-sample/${bookid}/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const updateOrder = (meth, path, data, props) => {
  const config = {
    method: meth,
    url: `${baseLabURL}${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const recordOfLoginandLogout = (data, props) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/employee/record-attendance/create/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      triggerToastSuccess(response.data.data);
      // triggerToast(response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getLabAvailableSlotsByDate = (path, data) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const bookingReschedule = (path, data, props) => {
  console.log(path, data);
  if (data && data?.startTime && data?.endTime) {
    const config = {
      method: 'post',
      url: `${baseLabURL}${path}`,
      headers: headers(),
      data: data,
    };

    return axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        errormsg(error, props);
      });
  } else {
    triggerToastError(`Please choose date and time for reschedule`);
  }
};
export const submitTestReport = (path, data, methord, props) => {
  const config = {
    method: methord,
    url: `${baseLabURL}${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      // triggerToastSuccess(response && response.data && response.data.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const submitSelectedTestReport = (id, data, props) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/laboratory/test-report/generate/${id}/`,
    headers: headers(),
    responseType: 'arraybuffer',
    data: {
      id: data,
    },
  };

  return axios(config)
    .then(function (response) {
      // triggerToastSuccess(response && response.data && response.data.data.message);
      return response;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const submitMultiTestReport = (path, data, props) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      // triggerToastSuccess(response && response.data && response.data.data.message);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getLabBookingDetails = (path) => {
  const config = {
    method: 'get',
    url: `${baseLabURL}${path}`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const labBookingTestResults = (path, data) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}${path}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getdashborad = (data) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/patients/profiles/all-option/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data.data);

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error.response;
    });
};
export const postdashboard = (data) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/patients/profiles/find-by-details/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data.data);

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error.response;
    });
};
export const admindash = (path, data) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/patients/profiles/registrationtotal/${path}/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data);

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error.response;
    });
};
export const countofdoc = (path, data) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/patients/consultations/appointment-speciality/${path}/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data);

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error.response;
    });
};
export const countappoint = (path, data) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/patients/consultations/appointment-counts/${path}/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data);

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error.response;
    });
};
export const countlab = (path, data) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/hrms/laboratory/labtotalcount/${path}/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data);

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error.response;
    });
};
export const numberofpa = (data) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/patients/profiles/patient-counts/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data);

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error.response;
    });
};
export const awardsdetails = (data, emd) => {
  const config = {
    method: 'put',
    url: `${baseLabURL}/employee/${emd}/awards/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data);

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error.response;
    });
};
export const awardsdelete = (data, emd) => {
  const config = {
    method: 'delete',
    url: `${baseLabURL}/employee/${emd}/awards/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data);

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error.response;
    });
};
export const certificatedetails = (data, emd) => {
  const config = {
    method: 'put',
    url: `${baseLabURL}/employee/${emd}/certifications/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data);

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error.response;
    });
};
export const documentdetails = (data, emd) => {
  const config = {
    method: 'put',
    url: `${baseLabURL}/employee/${emd}/documents/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data);

      return response.data.data.filename;
    })
    .catch(function (error) {
      console.log(error);
      return error.response;
    });
};
export const leaverequest = (data) => {
  const config = {
    method: 'post',
    url: `${baseLabURL}/attendance/leave-requests/`,
    headers: headers(),
    data: data,
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data);

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error.response;
    });
};
export const leaverequesthistroy = (id) => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/attendance/leave-requests/${id}/`,
    headers: headers(),
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data);

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error.response;
    });
};
export const masterleavecode = () => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/masters/attendance/leave-code/`,
    headers: headers(),
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data);

      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const leavebalance = (id) => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/attendance/get-leave-balances/${id}/`,
    headers: headers(),
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data);

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error.response;
    });
};
export const deleteleaverequest = (id) => {
  const config = {
    method: 'delete',
    url: `${baseLabURL}/leave/leave-requests/${id}/`,
    headers: headers(),
  };
  return axios(config)
    .then(function (response) {
      console.log(response.data);

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error.response;
    });
};
export const getTestLists = (id, props) => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/laboratory-test-mappings/${id}/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getAllTests = (props) => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/laboratory-test-mappings/get-tests/all/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
// export const getAllTests1 = (id) => {
//   const config = {
//     method: 'get',
//     url: `${baseLabURL}/laboratory-test-mappings/get-tests/all/?layout=1`,
//     headers: headers(),
//   };

//   return axios(config)
//     .then(function (response) {
//       return response.data.data;
//     })
//     .catch(function (error) {
//       errormsg(error);
//     });
// };
export const getAllTestsTree = (props) => {
  const config = {
    method: 'GET',
    url: `${baseLabURL}/laboratory-test-mappings/get-tests/all/?layout=2`,
    headers: headers(),
    // data: {
    //   allLabTypes: ['Internal'],
    // },
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getLabTests = (id) => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/laboratory-test-mappings/get-tests/${id}/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getMultiLabTests = (lab, type, props) => {
  const data = {
    labId: lab.length > 0 ? lab : undefined,
    allLabTypes: type.length > 0 ? type : undefined,
  };
  const config = {
    method: 'POST',
    url: `${baseLabURL}/laboratory-test-mappings/get-tests-all-labs/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getMultiLabTestsTree = (lab, type, props) => {
  const data = {
    labId: lab.length > 0 ? lab : undefined,
    allLabTypes: type.length > 0 ? type : undefined,
  };
  const config = {
    method: 'POST',
    url: `${baseLabURL}/laboratory-test-mappings/get-tests-all-labs/?layout=2`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getAllLabBooking = (status, value, startDate, endDate, pageNumber, itemPerPage, props) => {
  // const config = {
  //   method: 'get',
  //   url: `${baseLabURL}/laboratory/bookings/get-all/${status}/${value}/`,
  //   headers: headers(),
  // };

  // return axios(config)
  //   .then(function (response) {
  //     return response.data.data;
  //   })
  //   .catch(function (error) {
  //     errormsg(error);
  //   });
  console.log(startDate, 'befor');
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  console.log(startDate, 'after');
  const srt = moment(startDate).format('YYYY-MM-DD');
  const arrstr = `${srt}T00:00:00+05:30`;
  // const day = moment(srt, 'DD-MM-YYYY').subtract(1, 'days');
  const endt = moment(endDate).format('YYYY-MM-DD');
  const arrend = `${endt}T23:59:00+05:30`;
  console.log(`Date format Changed ::: ${arrstr}`, arrend, arrstr);
  const config = {
    method: 'POST',
    url: `${baseLabURL}/laboratory/bookings/all/list/`,
    headers: headers(),
    data: {
      searchCriteria: {
        status: status,
        labId: value === 'all' ? undefined : value,
        slot__startTime__gte: arrstr,
        slot__endTime__lte: arrend,
        slot__ne: null,
      },
      itemsPerPage: itemPerPage,
      pageNumber: pageNumber,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getAllLabBookingSearch = (status, value, startDate, endDate) => {
  console.log(startDate, 'befor');
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  console.log(startDate, 'after');
  const srt = moment(startDate).format('YYYY-MM-DD');
  const arrstr = `${srt}T00:00:00+05:30`;
  // const day = moment(srt, 'DD-MM-YYYY').subtract(1, 'days');
  const endt = moment(endDate).format('YYYY-MM-DD');
  const arrend = `${endt}T23:59:00+05:30`;
  console.log(`Date format Changed ::: ${arrstr}`, arrend, arrstr);
  const config = {
    method: 'POST',
    url: `${baseLabURL}/laboratory/bookings/all/list/`,
    headers: headers(),
    data: {
      searchCriteria: {
        status: status,
        labId: value === 'all' ? undefined : value,
        slot__startTime__gte: arrstr,
        slot__endTime__lte: arrend,
        slot__ne: null,
      },
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data.data.items;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getAllLabBookingStatusSearch = (bookingId, patient, pincode, itemPerPage, pageNumber, props) => {
  const config = {
    method: 'POST',
    url: `${baseLabURL}/laboratory/bookings/all/list/`,
    headers: headers(),
    data: {
      searchCriteria: {
        status__in: [
          'DRAFT',
          'SCHEDULED',
          'RESCHEDULED',
          'ASSIGNED',
          'RE-ASSIGNED',
          'ACCEPTED',
          'DECLINED',
          'STARTED',
          'REACHED',
          'SAMPLED',
          'COLLECTED',
          'SAMPLE-SUBMITTED',
          'SAMPLE-RECEIVED',
          'REPORT-READY',
          'CANCELLED',
        ],
        patientInfo__firstName__icontains: patient || undefined,
        // status__in: [],
        pincode__icontains: pincode || undefined,
        bookingId: bookingId || undefined,
      },
      itemsPerPage: itemPerPage,
      pageNumber: pageNumber,
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getBookingsById = (id, props) => {
  const config = {
    method: 'get',
    url: `${baseLabURL}/laboratory/bookings/get/${id}/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getBookingsStatusById = (id, props) => {
  const data = {
    bookingId: id,
  };
  const config = {
    method: 'POST',
    url: `${baseLabURL}/home-collection/bookings/stat/get/`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getTestReport = (id, props) => {
  const config = {
    method: 'GET',
    url: `${baseLabURL}/laboratory/test-report/${id}/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      console.log(response, 'response');
      console.log(response.data, 'response.data');
      console.log(response.data.data, 'response.data.dhar');
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const getUpdateLabTests = (data, apiData, props) => {
  console.log(apiData, 'apiDataapiData');
  let url = '/laboratory-test-mappings/';
  if (apiData.id) url = '/laboratory-test-mappings/' + apiData.id + '/';
  const config = {
    method: apiData.method,
    url: baseLabURL + url,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response, 'getUpdateLabTests');
      triggerToastSuccess(response && response.data && response.data.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};

export const getAddLabSlots = (data, labId, method, props) => {
  const config = {
    method: method,
    url: baseLabURL + '/laboratory/slots/' + labId + '/',
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response);
      triggerToastSuccess(response && response.data && response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // if (error && error.response) {
      //   if (error.response.data.errors)
      //     triggerToastError(`${error && error.response && error.response.data.errors[0].message}`);
      // } else {
      //   triggerToastError(`${error && error.response && error.response.message}`);
      // }
      // triggerToastError(`${error.response.data.message}`);
    });
};
export const getAddLabSlotsBulk = (data, labId, method, props) => {
  const config = {
    method: method,
    url: baseLabURL + '/laboratory/create/slot/bulk/',
    headers: headers(),
    data: data,
  };
  console.log(config);

  return axios(config)
    .then(function (response) {
      console.log(response);
      triggerToastSuccess(response && response.data && response.data.message);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
      // if (error && error.response) {
      //   if (error.response.data.errors)
      //     triggerToastError(`${error && error.response && error.response.data.errors[0].message}`);
      // } else {
      //   triggerToastError(`${error && error.response && error.response.message}`);
      // }
      // triggerToastError(`${error.response.data.message}`);
    });
};

export const getLabSlots = (labId, serviceId, props) => {
  let service = '';
  if (serviceId) {
    service = `?serviceId=${serviceId}`;
  } else {
    service = `/`;
  }
  const config = {
    method: 'get',
    url: baseLabURL + '/laboratory/slots/' + labId + service,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      console.log(response, 'getLabSlots');
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const sendSampleTest = (data, props) => {
  const config = {
    method: 'POST',
    url: baseLabURL + '/laboratory/test-sample/',
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response, 'getLabSlots');
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error, props);
    });
};
export const sendSampleTestCollected = (data, id) => {
  const config = {
    method: 'POST',
    url: baseLabURL + '/home-collection/bookings/stat/location/SAMPLE-SUBMITTED/' + id + '/',
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response, 'getLabSlots');
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      errormsg(error);
      return error.response.data;
    });
};
export const createExternalLabMappingService = (data, update, props) => {
  const config = {
    method: update ? 'PUT' : 'POST',
    url: `${baseLabURL}/laboratory/externalAPIMapping/${update ? data.id + '/' : ''}`,
    headers: headers(),
    data: data,
  };

  return axios(config)
    .then(function (response) {
      console.log(response, 'getLabSlots');
      triggerToastSuccess(response && response.data && response.data.data.message);
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      errormsg(error, props);
      return error.response.data;
    });
};
export const getExternalLabByIDService = (id, props) => {
  const config = {
    method: 'GET',
    url: baseLabURL + `/laboratory/externalAPIMapping/${id}/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      console.log(response, 'getLabSlots');
      // triggerToastSuccess(response && response.data && response.data.data.message);
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      errormsg(error, props);
      return error.response.data;
    });
};
export const fileAttachmentDownloadURLGen = async (fileName, props) => {
  // const encodedFileName = encodeURIComponent(fileName);
  let signedUrl;
  try {
    await axios({
      method: 'POST',
      url: `${baseLabURL}/file-storage/get-signed-url/`,
      headers: {
        applicationLabel: 'Lab-Portal',
        correlationId: uuidv4(),
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      data: {
        filename: fileName,
        objectType: 'close',
        apiType: 'download',
      },
    })
      .then((res) => {
        if (res.data.status >= 200 && res.data.status < 400) {
          signedUrl = res.data.data.signedUrl;
        }
      })
      .catch((error) => {
        errormsg(error, props);
      });
  } catch (error) {
    // console.log(error, 'error');
  }
  return signedUrl;
};
export const fileAttachmentDownload = (url, fileType, fileName, props) => async (dispatch, getState) => {
  // console.log('fileAttachmentDownload service');
  try {
    await axios({
      method: 'GET',
      url: `${url}`,
      responseType: 'arraybuffer',
    })
      .then((res) => {
        if (res.status >= 200) {
          if (res.data) {
            const blob = new Blob([res.data], { type: fileType });
            saveAs(blob, fileName);
          }
        }
      })
      .catch((err) => {
        errormsg(err, props);
      });
  } catch (error) {
    // dispatch(addOverlayLoading(false));
    console.log(error);
    // console.log(error.res, 'error');
  }
};
export const fileAttachmentUploadTemplate = (file, url, props) => async (dispatch) => {
  console.log(file[0].type, 'fileAttachmentUpload');
  // try {
  //   return await axios({
  //     method: 'POST',
  //     url: `${url}`,
  //     timeout: 30000,
  //     headers: {
  //       'Content-Type': file[0]?.type, // This is set to application/flac
  //       'Content-Disposition': `attachment; filename=${file[0].name}`,
  //       applicationLabel: 'Pharmacy-Portal',
  //       Authorization: `Bearer ${getToken()}`,
  //     },
  //     data: file[0],
  //   })
  //     .then(async (res) => {
  //       console.log(res);
  //       // if (res.status >= 200) {
  //       //   if (res.data.status >= 200 && res.data.status < 400) {
  //       //     const url = res.data.data.signedUrl;
  //       //     const fileName = res.data.data.filename;
  //       //     const fileUrl = res.data.data.fileUrl;
  //       //     const response = await fileAttachmentUpload(fileDetails, props, url, fileName, fileUrl);
  //       //     console.log(response, 'response');

  //       //     if (response.status === 200) return fileName;
  //       //     triggerToastError(`File Upload failed. Try after Sometimes.`);
  //       //   }
  //       // }
  //     })
  //     .catch((err) => {
  //       console.log(err.response, 'in');
  //       triggerToastError(err.response);
  //     });
  // } catch (error) {
  //   console.log(error, 'error');
  // }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // const formData = new FormData();
    // formData.append("file", new Blob([reader.result]), file.name);  // Found both online
    // formData.append('file', new Blob([reader.result]), file[0].name);
    reader.onload = () => {
      axios
        .request({
          headers: {
            'Content-Type': `${file[0]?.type}`, // This is set to application/flac
            'Content-Disposition': `attachment; filename=${file[0].name}`,
            applicationLabel: 'Lab-Portal',
            correlationId: uuidv4(),
            Authorization: `Bearer ${getToken()}`,
          },
          method: 'POST',
          data: Buffer.from(reader.result),
          url: url,
          timeout: 0,
          onUploadProgress: (progressEvent) => {
            const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100 - 10;
            console.log(uploadProgress, 'uploadProgress');
          },
        })
        .then((response) => {
          resolve(response);
          console.log(response, 'fileUpload');
        })
        .catch((err) => {
          console.log(err, 'err');
          errormsg(err, props);
          dispatch(loadingAction(false));
          reject(err.response);
        });
    };
    reader.onerror = (err) => {
      err = { error: { message: 'file read error' } };
      console.log(err);
      reject(err);
    };
    reader.readAsArrayBuffer(file[0]);
  });
};
export const fileAttachmentDownloadTemplate = (url, fileType, fileName, props) => async (dispatch, getState) => {
  // console.log('fileAttachmentDownload service');
  // const t = url?.split('/');
  // const t2 = t.pop();
  // const t3 = t2.split('?');
  try {
    await axios({
      method: 'GET',
      url: `${url}`,
      // responseType: 'arraybuffer',
      responseType: 'blob',
      headers: headers(),
    })
      .then((res) => {
        if (res.status >= 200) {
          if (res.data) {
            console.log(res, 'ressss');
            // const contentDisposition = res.data.headers.get('content-disposition') || '';
            // const matches = /filename=([^;]+)/gi.exec(contentDisposition);
            // const fileName = (matches[1] || 'untitled').trim();
            // const contentDisposition = res.headers['content-disposition'];
            // const match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
            // const filename = match[1];
            // console.log(filename, 'sjdviusjduvhsiudhv');
            // let headerLine = res.data.headers['content-disposition'];
            // const headerLine = res.headers['Content-Disposition'];
            // const startFileNameIndex = headerLine.indexOf('"') + 1;
            // const endFileNameIndex = headerLine.lastIndexOf('"');
            // const filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
            // console.log(filename, 'jhdbvabdhc');
            const blob = new Blob([res.data], { type: fileType });
            console.log(blob, 'blob');
            saveAs(blob, fileName);
          }
        }
      })
      .catch((err) => {
        errormsg(err, props);
      });
  } catch (error) {
    // dispatch(addOverlayLoading(false));
    console.log(error);
    // console.log(error.res, 'error');
  }
};
export const fileAttachmentDownloadURLGenTemplate = async (fileName, props) => {
  // const encodedFileName = encodeURIComponent(fileName);
  let signedUrl;
  // try {
  await axios({
    method: 'POST',
    url: `${baseLabURL}/file-storage/get-signed-url/`,
    headers: headers(),
    data: {
      filename: fileName,
      objectType: 'close',
      apiType: 'download',
    },
  })
    .then((res) => {
      if (res.data.status >= 200 && res.data.status < 400) {
        signedUrl = res.data.data.signedUrl;
      }
    })
    .catch((error) => {
      errormsg(error, props);
    });
  // } catch (error) {
  //   // console.log(error, 'error');
  // }
  return signedUrl;
};
export const getTemplateStatusTypeService = (props) => {
  const config = {
    method: 'GET',
    url: `${baseLabURL}/bulk-upload/status/lists/`,
    headers: headers(),
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      errormsg(error, props);
    });
};
export const getUploadTemplateStatusService = (searchCriteria, props) => {
  const config = {
    method: 'POST',
    url: `${baseLabURL}/bulk-upload/all/list/`,
    headers: headers(),
    data: searchCriteria,
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      errormsg(error, props);
    });
};
export const getTestPriortyDataByTestIdsService = (data) => async (dispatch, getState) => {
  dispatch(loadingAction(true));
  const config = {
    method: 'POST',
    url: `${baseLabURL}/laboratory-test-mappings/construct-tests-priority/`,
    headers: headers(),
    data,
  };

  return await axios(config)
    .then(function (response) {
      dispatch(loadingAction(false));
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      dispatch(loadingAction(false));
      errormsg(error);
    });
};
export const getTestPriortyDataByTestIdsServiceLayout = (data, props) => async (dispatch, getState) => {
  dispatch(loadingAction(true));
  const config = {
    method: 'POST',
    url: `${baseLabURL}/laboratory-test-mappings/construct-tests-priority/?layout=1`,
    headers: headers(),
    data,
  };

  return await axios(config)
    .then(function (response) {
      dispatch(loadingAction(false));
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      dispatch(loadingAction(false));
      errormsg(error, props);
    });
};
