export const homecollectionReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_POSTDASH_DATA':
      return {
        ...state,
        postdash: action.payload,
      };
    case 'GET_DASH_DATA':
      return {
        ...state,
        dashboard: action.payload,
      };
    case 'GET_DASH1_DATA':
      return {
        ...state,
        dashboard1: action.payload,
      };

    case 'GET_DASHCITY_DATA':
      return {
        ...state,
        dashboardcity: action.payload,
      };
    case 'GET_ADDASH_DATA':
      return {
        ...state,
        addashboard: action.payload,
      };
    case 'GET_ADDASH1_DATA':
      return {
        ...state,
        addashboard1: action.payload,
      };

    case 'GET_ADDASHCITY_DATA':
      return {
        ...state,
        addashboardcity: action.payload,
      };
    case 'GET_DASHCIYTPIN_DATA':
      return {
        ...state,
        dashboardcitypin: action.payload,
      };
    case 'GET_ADMINDATA':
      return {
        ...state,
        adminpa: action.payload,
      };
    case 'GET_COUNTDATA':
      return {
        ...state,
        countofdoc: action.payload,
      };
    case 'GET_COUNTAPPOINTDATA':
      return {
        ...state,
        countofappoint: action.payload,
      };
    case 'GET_COUNTLABDATA':
      return {
        ...state,
        countoflab: action.payload,
      };
    case 'GET_PATIENTCOUNT':
      return {
        ...state,
        countofpatients: action.payload,
      };

    default:
      return state;
  }
};
export default homecollectionReducer;
