export const companyReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_HOSPITAL_DATA':
      return {
        ...state,
        hospital: action.payload,
      };
    case 'GET_BRANCH_DATA':
      return {
        ...state,
        branch: action.payload,
      };
    case 'GET_Employee_DATA':
      return {
        ...state,
        employee: action.payload,
      };
    case 'GET_DOCTOR_DATA':
      return {
        ...state,
        doctor: action.payload,
      };
    case 'GET_ALL_EMPLOYEES':
      return {
        ...state,
        employees: action.payload,
      };
    case 'GET_DOCTOR_SCHEDULE_DATA':
      return {
        ...state,
        schedule: action.payload,
      };
    case 'GET_DOCTOR_SLOT_DATA':
      return {
        ...state,
        slots: action.payload,
      };
    case 'GET_ROLE_DATA':
      return {
        ...state,
        roles: action.payload,
      };
    case 'GET_ROLE_FUNCTIONS_DATA':
      return {
        ...state,
        rFunctions: action.payload,
      };
    case 'GET_ROLE_LIST_DATA':
      return {
        ...state,
        roleList: action.payload,
      };
    case 'GET_REFERRAL_DATA':
      return {
        ...state,
        referral: action.payload,
      };
    case 'GET_REFERRAL_MAPPING_DATA':
      return {
        ...state,
        referralMap: action.payload,
      };
    case 'GET_REFERRAL_REQUEST_DATA':
      return {
        ...state,
        referralRequest: action.payload,
      };
    default:
      return state;
  }
};
export default companyReducer;
