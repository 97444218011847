export const labReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_LAB_DATA':
      return {
        ...state,
        [action.path]: action.payload,
      };
    case 'GET_PACK_DATA':
      return {
        ...state,
        [action.path]: action.payload,
      };
    case 'PACKAGE_OPTION':
      return {
        ...state,
        packageOption: action.payload,
      };
    case 'GET_LAB_PAGINATION_DATA':
      return {
        ...state,
        labPaginationData: action.payload,
      };
    case 'GET_GROUP_DATA':
      return {
        ...state,
        listpre: action.payload,
      };
    case 'GET_SUPGROUP_DATA':
      return {
        ...state,
        suplist: action.payload,
      };
    case 'GET_SUBGROUP_BY_GROUP':
      return {
        ...state,
        subgroupByGroupId: action.payload,
      };
    case 'GET_LAB_EXECUTIVE_DATA':
      return {
        ...state,
        labExecutiveByLabId: action.payload,
      };
    case 'GET_LAB_AVAIL_SLOTS_DATE':
      return {
        ...state,
        labAvailableSlotsByDate: action.payload,
      };
    case 'GET_ASSIGN_EXECUTIVE_DATA':
      return {
        ...state,
        labExecutiveByLabIdAndPincode: action.payload,
      };
    case 'GET_PINCODE_EXECUTIVE_DATA':
      return {
        ...state,
        pincodeExecutive: action.payload,
      };
    case 'GET_LAB_DETAILS_BY_BOOKINGID':
      return {
        ...state,
        labDetailsByBookingId: action.payload,
      };
    case 'GET_ALL_SEARCHDATA':
      return {
        ...state,
        allSearchData: action.payload,
      };
    case 'GET_ALLTESTMASTERDATA':
      return {
        ...state,
        getAllTestMasterData: action.payload,
      };
    case 'GET_LAB_PINCODE_PAGENATION':
      return {
        ...state,
        labpincodePagenation: action.payload,
      };
    case 'GET_ALLTESTMASTERDATATREE':
      return {
        ...state,
        getAllTestMasterDataTree: action.payload,
      };
    case 'GET_LAB_SLOTS':
      return {
        ...state,
        getLabSlots: action.payload,
      };
    case 'GET_SAMPLE_TESTS':
      return {
        ...state,
        getSampleTests: action.payload,
      };
    case 'GET_EXTERNAL_LAB_DATA':
      return {
        ...state,
        getExternalLabDetails: action.payload,
      };
    case 'GET_TEMPLEATE_STATUS':
      return {
        ...state,
        getTemplateStatus: action.payload,
      };
    case 'GET_UPLOADEDTEMPLEATE_STATUS':
      return {
        ...state,
        getUploadedTemplateStatus: action.payload,
      };
    default:
      return state;
  }
};
export default labReducer;
