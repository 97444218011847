/* eslint-disable lines-between-class-members */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import Geocode from 'react-geocode';
import IdleTimer from 'react-idle-timer';
import { removeToken, getToken } from './utils/utils';
import './App.scss';
import OverlayLoader from './redux/actions/OverlayLoader';
import { logout } from './services/services';
import Loadpage from './redux/actions/Loadpage';

const googleMapKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
Geocode.setApiKey(googleMapKey);
Geocode.setLanguage('en');
Geocode.setRegion('es');
// Geocode.setLocationType('ROOFTOP');
Geocode.enableDebug();

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({
  ...state,
});
class App extends Component {
  state = {};

  componentDidMount() {
    this.onRouteChanged();
  }
  navigatorfunction(data, propsdata) {
    console.log(data);
    console.log(propsdata);
    data.getCurrentPosition(function (position) {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      console.log(lat, lng);
      Geocode.fromLatLng(lat, lng).then(
        async (response) => {
          const addressComponents = response.results[0].address_components;
          console.log(response.results[0].formatted_address);
          let country;
          let pincode;
          let state;
          let city;
          let area;
          // eslint-disable-next-line array-callback-return
          addressComponents.map((countryId) => {
            if (countryId.types[0] && countryId.types[0] === 'country') {
              country = countryId.long_name;
            }
            if (countryId.types[0] && countryId.types[0] === 'postal_code') {
              pincode = countryId.long_name;
            }
            if (countryId.types[0] && countryId.types[0] === 'administrative_area_level_1') {
              state = countryId.long_name;
            }
            if (countryId.types[0] && countryId.types[0] === 'administrative_area_level_2') {
              city = countryId.long_name;
            }
            if (countryId.types[0] && countryId.types[0] === 'locality') {
              area = countryId.long_name;
            }
          });
          const address = `${city || ''}, ${area || ''}, ${state || ''}, ${country || ''} - ${pincode || ''}`;
          const emp = localStorage.getItem('username');
          const tok = localStorage.getItem('accessToken');
          const data = {
            username: emp,
            location: address,
          };
          if (emp && tok) {
            const res = await logout(data);
            console.log(res);
            propsdata.history.push('/login');
            removeToken();
          }
          // const address = response.results[0].formatted_address;
          // console.log(address, 'address');
        },
        (error) => {
          console.error(error, 'googleApi error');
        },
      );
    });
  }
  render() {
    const navbarComponent = !this.state.isFullPageLayout ? <Navbar props={this.props} /> : '';
    const sidebarComponent = !this.state.isFullPageLayout ? <Sidebar func={this.state.functionsList} /> : '';
    return (
      <>
        <ToastContainer />
        <div className="container-scroller">
          {navbarComponent}
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            {sidebarComponent}
            <div className={!this.state.isFullPageLayout ? 'main-panel' : 'main-panel vh-100'}>
              <div className="content-wrapper">
                <IdleTimer
                  timeout={1000 * 60 * 15}
                  debounce={250}
                  onIdle={() => {
                    if (document.visibilityState === 'visible') {
                      console.log('tab is active');
                      const token = getToken();
                      if (navigator.geolocation) {
                        console.log(token);
                        console.log(this.props, 'prps');
                        this.navigatorfunction(navigator.geolocation, this.props);
                      }
                    } else {
                      const logoutInoutsideTab = async () => {
                        const emp = localStorage.getItem('username');
                        const tok = localStorage.getItem('accessToken');
                        const locationData = localStorage.getItem('loginLocation');
                        const data = {
                          username: emp,
                          location: locationData,
                        };
                        if (emp && tok) {
                          const res = await logout(data);
                          console.log(res);
                          this.props.history.push('/login');
                          removeToken();
                        }
                      };
                      logoutInoutsideTab();
                    }

                    // removeToken();
                  }}
                />
                <AppRoutes />

                <OverlayLoader />
                <Loadpage />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
    if (prevProps.global.tokenValid !== this.props.global.tokenValid) {
      if (!this.props.global.tokenValid) {
        localStorage.clear();
        this.props.history.push('/login');
        this.onRouteChanged();
      }
    }
  }

  onRouteChanged() {
    const body = document.querySelector('body');
    if (this.props.location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
    } else {
      body.classList.remove('rtl');
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/login', '/referral-doctor'];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true,
        });
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }
}
App.propTypes = {
  location: PropTypes.any,
  history: PropTypes.object,
  global: PropTypes.object,
  getCategory: PropTypes.func,
  getHospitalData: PropTypes.func,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
